import { useInfiniteQuery, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { ElasticService } from '../../services';
import { IGetProducts } from '../../services/elastic';
import { IAggs } from '../../services/elastic/types';
import { PaginationList } from '../../services/magento/types';
import { IProduct } from '../../services/product/types';
import { PRODUCT } from '../../utils/react-query-keys';

interface UseInfiniteProductProps {
    config?: UseInfiniteQueryOptions<UseIfiniteOrderResponse>;
    filterGroups?: any;
    sortOrderField?: IGetProducts['sort'];
    aggs?: IAggs;
}
export interface UseIfiniteOrderResponse extends PaginationList<IProduct> {
    pagesTotal: number;
    page: number;
    nextPage: number | boolean;
}

export function useInfiniteProducts({
    config,
    filterGroups = [],
    sortOrderField = 'relevance',
    aggs
}: UseInfiniteProductProps) {
    return useInfiniteQuery<UseIfiniteOrderResponse>(
        [PRODUCT, filterGroups, sortOrderField, aggs],
        async ({ pageParam = 1 }) => {
            const pageSize = 20;
            const response = await ElasticService.getProducts({
                filter: filterGroups,
                limit: pageSize,
                page: pageParam ? pageParam : 1,
                sort: sortOrderField,
                aggs
            });

            const pagesTotal = Math.ceil(response.total_count ? response.total_count / pageSize : 0);
            return {
                ...response,
                items: response.items ? response.items : [],
                pagesTotal,
                page: pageParam,
                nextPage: !response.items || pagesTotal <= pageParam ? false : pageParam + 1
            };
        },
        {
            getNextPageParam: (page) => page.nextPage,
            ...config,
            refetchOnMount: false
        }
    );
}
