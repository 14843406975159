import dynamic from 'next/dynamic';
import Layout from '../Layout';
import LayoutFitter from '../Layout-fitter';
import { ProductSearchFiltersProvider } from './hooks/useProductSearchFilters';
const BreadcrumbSearch = dynamic(() => import('./components/Breadcrumb'), { ssr: false });
const BannerCategory = dynamic(() => import('./components/Banner-category'), { ssr: false });
const DescriptionCategory = dynamic(() => import('./components/Description-category'), { ssr: false });
const SearchTerm = dynamic(() => import('./components/SearchTerm'), { ssr: false });
const CategoryCarousel = dynamic(() => import('./components/CategoryCarousel'), { ssr: false });
const FilterMobile = dynamic(() => import('./components/FilterMobile'), { ssr: false });
const Items = dynamic(() => import('./components/Items'), { ssr: false });
const CouponsBanner = dynamic(() => import('../../molecules/Coupons-banner'), { ssr: false });

function ProductSearch() {
    return (
        <Layout>
            <ProductSearchFiltersProvider>
                <LayoutFitter>
                    <BreadcrumbSearch />
                    <BannerCategory />
                    <DescriptionCategory />
                    <SearchTerm />
                    <CategoryCarousel className="flex md:hidden" />
                    <FilterMobile />
                    <Items />
                </LayoutFitter>
                <LayoutFitter withoutPaddingMobile>
                    <div className="pt-9 md:pt-12 md:pb-12">
                        <CouponsBanner />
                    </div>
                </LayoutFitter>
            </ProductSearchFiltersProvider>
        </Layout>
    );
}

export default ProductSearch;
