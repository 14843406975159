import { useEffect, useState } from 'react';
import IconCart from '../../atoms/icons/Cart';
import IconStar from '../../atoms/icons/Star';
import { Button, InputCoupon } from '../../molecules';
import Layout from '../Layout';
import LayoutFitter from '../Layout-fitter';

import { FormikProvider, useFormik } from 'formik';
import Link from 'next/link';
import { useCart } from '../../../hooks';
import { CartItemWithElastic } from '../../../services/cart/types';
import { currencyBrl } from '../../../utils/getFormattedValue';
import CartItemComponent from '../../molecules/CartItem';
import { ICartItemVariant } from '../../molecules/CartItem/types';
import ZipCode from '../Product-details/components/Zip-code';
import { AddressService, CartService, MapService, ProductService, ReactGa4Service } from '../../../services';
import { IProducItems } from '../../../services/react-ga4/types';
import Alert from '../../molecules/Alert';
import { useRouter } from 'next/router';
import { useEstablishment } from '../../../hooks/use-establishment';
import { ADDRESS } from '../../../utils/react-query-keys';
import { useQuery } from '@tanstack/react-query';
import { Address } from '../../../services/address/types';
import useEstimateShipping from '../../../hooks/use-estimete-shipping';
import { useIsCnpjAvailable } from '../../../hooks/use-is-cnpj-available';

interface SellerGroup {
    seller: string;
    products: CartItemWithElastic[];
}

const Cart = () => {
    const [isCnpjAvailable, setIsCnpjAvailable] = useState(true);

    useEffect(() => {
        const checkCnpj = async () => {
            const result = await useIsCnpjAvailable();

            setIsCnpjAvailable(result);
        };

        checkCnpj();
    }, []);

    const { data: cartData, isLoading: isLoadingCart } = useCart();
    const [hasFired, setHasFired] = useState<boolean>(false);

    const router = useRouter();

    const positiveRating = 4;
    const emptyRating = 5 - positiveRating;

    // const goldStars = Array.from({ length: positiveRating }, (_, index) => {
    //     return <IconStar key={index} fill="#FFCB05" width={16} height={16} />;
    // });

    // const grayStars = Array.from({ length: emptyRating }, (_, index) => {
    //     return <IconStar key={index} fill="#DEDEDE" width={16} height={16} />;
    // });

    const ordersBySeller: SellerGroup[] =
        cartData?.items?.reduce((acc: SellerGroup[], item: CartItemWithElastic) => {
            let sellerGroup = acc.find((group) => group.seller === item.seller_name);

            if (sellerGroup) {
                sellerGroup.products.push(item);
            } else {
                acc.push({
                    seller: item.seller_name,
                    products: [item]
                });
            }

            return acc;
        }, []) || [];

    let items: IProducItems[] = [];
    const result = ordersBySeller.map((order) => {
        order.products.map((product) => {
            items.push({
                item_id: product.sku,
                item_name: product.name,
                price: product.price,
                quantity: product.qty
            });
        });
    });
    const { data: shippingValue, isLoading: loadingShipping } = useEstimateShipping();

    if (!hasFired) {
        const valueDatalayer = cartData?.info?.grand_total ?? 0;
        ReactGa4Service.onViewCart(items, Number(valueDatalayer));
        setHasFired(true);
    }

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const formik = useFormik({
        initialValues: {
            coupon: cartData?.info?.coupon_code || ''
        },
        onSubmit: () => {}
    });
    const unavailableProducts = CartService.getProductsUnavailableCart();

    return (
        <Layout>
            <LayoutFitter>
                <div className="flex flex-col gap-4 mt-8 mb-24 lg:flex-row">
                    <div className="w-full lg:w-[65%]">
                        <div className="flex items-center justify-center gap-4 mb-10 lg:justify-start">
                            <IconCart width={32} height={32} />
                            <h1 className="font-bold text-22">Carrinho</h1>
                        </div>

                        {/* Just shows zip code below cart title on mobile */}
                        <div className="block mb-4 lg:hidden">
                            <ZipCode />
                        </div>

                        <div>
                            {/* Oferta exclusiva para você */}
                            {/* <div>
                                <div className="flex items-center gap-10 py-2 pl-4 pr-4 bg-[#6054A4] rounded-8 text-white">
                                    <h1 className="font-semibold text-12 lg:text-16">Oferta exclusiva pra você</h1>
                                    <p className="text-12 text-end">
                                        Válido somente para a {windowWidth < 1024 && <br></br>}
                                        finalização desta compra agora
                                    </p>
                                </div>
                                <div className="flex flex-col items-center justify-between border-x-[0.5px] border-b-[0.5px] border-[#DFDFDF] p-4 rounded-b-8 mb-2 lg:flex-row">
                                    <div className="flex gap-4">
                                        <div className="flex items-center justify-center border-[0.5px] border-[#E0E0E0] object-contain w-[88px] h-24 rounded-8">
                                            <Image src={cimentoTest} alt="product image" />
                                        </div>

                                        <div className="flex flex-col gap-2">
                                            <p className="text-12 text-[#636363]">SKU 98274</p>
                                            <p className="font-semibold text-14 max-w-[300px]">
                                                Conjunto De Recipientes Herméticos Com Tampa E Trava,24peças
                                            </p>
                                            <div className="flex items-center gap-2">
                                                <p className="font-semibold text-14">4.8</p>
                                                <RatingStars>
                                                    {goldStars}
                                                    {grayStars}
                                                </RatingStars>
                                                <p className="text-12">(25)</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex gap-5 mt-2 lg:justify-between lg:mt-0 lg:gap-16">
                                        <div className="flex flex-col gap-1">
                                            <p className="font-semibold text-[#9FBE43]">Economize: R$ 150,00</p>
                                            <p className="font-semibold text-14">R$ 140,00 à vista</p>
                                            <p className="text-12">ou R$ 160,00 em 10x sem juros</p>
                                        </div>
                                        <span className="self-end lg:self-center">
                                            <Button
                                                text="Eu Quero!"
                                                style="
                                            border-radius: 36px; 
                                            min-width: 152px; 
                                            height: 48px; 
                                            background-color: #6054A4; 
                                            font-size: 18px;
                                        "
                                            />
                                        </span>
                                    </div>
                                </div>  
                            </div> */}

                            <div className="flex flex-col gap-2">
                                {ordersBySeller.map((order, index) => (
                                    <div
                                        className="flex flex-col gap-4 border-[0.5px] border-[#DFDFDF] p-4 rounded-8"
                                        key={index}
                                    >
                                        <div>
                                            <h1 className="text-12 text-[#636363]">
                                                Vendido por{' '}
                                                <span className="text-[#9FBE43] font-bold">
                                                    {order.seller?.toUpperCase()}
                                                </span>{' '}
                                                e entregue por{' '}
                                                <span className="text-[#9FBE43] font-bold">MATCON.CASA</span>
                                            </h1>
                                        </div>
                                        <div className="flex flex-col">
                                            {order.products.map((product, index) => (
                                                <div key={product.sku}>
                                                    <CartItemComponent
                                                        data={product}
                                                        variant={
                                                            windowWidth < 1024
                                                                ? ICartItemVariant.DEFAULT
                                                                : ICartItemVariant.PRIMARY_BIG
                                                        }
                                                    />
                                                    {index < order.products.length - 1 && (
                                                        <div className="h-[0.5px] bg-[#E6E6E6] my-4"></div>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="w-full lg:w-[35%]">
                        <h1 className="hidden mb-10 font-bold text-22 lg:block">Resumo do Pedido</h1>

                        <div className="flex flex-col gap-4">
                            <div className="hidden lg:block">
                                <ZipCode />
                            </div>

                            <FormikProvider value={formik}>
                                <div className="flex flex-col items-end gap-2 border-[0.5px] p-4 border-[#E6E6E6] rounded-8 lg:flex-row">
                                    <div className="flex flex-col items-start w-full gap-2">
                                        <label className="font-bold text-18">Cupom</label>
                                        <InputCoupon
                                            name="coupon"
                                            value={formik.values.coupon}
                                            className="rounded-full min-h-14 min-w-[240px]"
                                        />
                                    </div>
                                </div>
                            </FormikProvider>

                            <div className="flex flex-col p-4 border-[0.5px] border-[#E6E6E6] rounded-8">
                                <h1 className="mb-4 font-bold text-18">Totais</h1>

                                <div className="flex justify-between mb-4">
                                    <p>Subtotais</p>
                                    <p>{currencyBrl(cartData?.info?.subtotal ?? 0)}</p>
                                </div>

                                <div className="flex justify-between mb-4">
                                    <p>Frete a partir de</p>
                                    <p>
                                        {loadingShipping ? (
                                            <div className="bg-gray rounded-5 h-6 w-[130px] animate-pulse"></div>
                                        ) : (
                                            `R$ ${currencyBrl(shippingValue ?? 0)}`
                                        )}
                                    </p>
                                </div>

                                {cartData?.info?.discount_amount != undefined &&
                                    cartData?.info?.discount_amount < 0 && (
                                        <div className="flex justify-between mb-4 text-[#9FBE43]">
                                            <p>
                                                Desconto{' '}
                                                {cartData?.info.coupon_code && ` - ${cartData.info.coupon_code}`}
                                            </p>
                                            <p>
                                                {loadingShipping ? (
                                                    <div className="bg-gray rounded-5 h-6 w-[130px] animate-pulse"></div>
                                                ) : (
                                                    `R$ ` + currencyBrl(cartData?.info.discount_amount ?? 0)
                                                )}
                                            </p>
                                        </div>
                                    )}

                                <div className="flex justify-between mb-2 font-bold text-18">
                                    <p>TOTAL</p>
                                    <p>R$ {currencyBrl(cartData?.info.grand_total ?? 0)} à vista</p>
                                </div>
                                {/* <span className="flex flex-col text-12">
                                    <p className="self-end">no PIX ou Boleto</p>
                                    <p className="self-end">ou R$ {cartData?.info.grand_total} em até 10x sem Juros</p>
                                </span> */}
                            </div>

                            {unavailableProducts.length > 0 && (
                                <div className="">
                                    <Alert>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: 'Existem produtos sem estoque, por favor remova-os do carrinho para continuar.'
                                            }}
                                        ></div>
                                    </Alert>
                                </div>
                            )}

                            {!isCnpjAvailable && (
                                <div className="">
                                    <Alert>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: 'Estamos em fase de construção, por enquanto não atendemos CNPJ.'
                                            }}
                                        ></div>
                                    </Alert>
                                </div>
                            )}

                            <Button
                                text="Fechar Pedido"
                                disabled={unavailableProducts.length > 0 || !isCnpjAvailable}
                                style="border-radius: 32px;"
                                className="w-full"
                                onClick={() => {
                                    router.push('/checkout');
                                }}
                            />
                        </div>
                    </div>
                </div>
            </LayoutFitter>
        </Layout>
    );
};

export default Cart;
