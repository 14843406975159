import { useEffect, useState } from 'react';
import { useBlockConfig } from '../../../../../../hooks/use-block-config';
import { useEstimateShippingProduct } from '../../../../../../hooks/use-estimate-shipping-product';
import { IProduct } from '../../../../../../services/product/types';
import { currencyBrl } from '../../../../../../utils/getFormattedValue';
import { useQtyProduct } from '../../../hooks/useQtyProduct';
import { useEstablishment } from '../../../../../../hooks/use-establishment';

interface IShipping {
    data: IProduct;
}
function Shipping({ data }: IShipping) {
    const [isEmpty, setIsEmpty] = useState(false);
    const { qty } = useQtyProduct();
    const { data: config } = useBlockConfig();
    const { data: establishment } = useEstablishment();
    const { data: estimateShipping, isLoading } = useEstimateShippingProduct(data?.sku, qty, {
        enabled: !isEmpty
    });

    useEffect(() => {
        if (estimateShipping && estimateShipping.length === 0) {
            setIsEmpty(true);
        }
    }, [estimateShipping]);

    useEffect(() => {
        if (isEmpty) {
            setIsEmpty(false);
        }
    }, [establishment?.zipCode]);

    if (isEmpty) {
        return <div className="text-12 text-[#636363]">Entrega indisponivel (CEP {establishment?.zipCode})</div>;
    }

    const getContent = () => {
        if (isLoading) {
            return (
                <>
                    <div className="w-10/12 rounded-md bg-gray animate-pulse h-3" />
                    <div className="w-8/12 rounded-md bg-gray animate-pulse h-3" />
                </>
            );
        }

        if (estimateShipping && estimateShipping?.length > 0) {
            return (
                <>
                    {estimateShipping.map((shipping) => (
                        <div className="text-12" key={shipping.method_code}>
                            Receba pelo {shipping.method_title} por{' '}
                            <strong>R$ {currencyBrl(shipping.price_incl_tax)}</strong>
                        </div>
                    ))}
                    <div
                        className="text-12 text-[#636363]"
                        dangerouslySetInnerHTML={{
                            __html: config?.detalhes_produto_entrega_mensagem as any
                        }}
                    ></div>
                </>
            );
        }
    };

    return <div className="flex flex-col gap-2">{getContent()}</div>;
}

export default Shipping;
