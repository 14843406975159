import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { BlockService } from '../../services';
import { IBlockHallHome } from '../../services/block';
import { BLOCK_HALL_HOME } from '../../utils/react-query-keys';

export const useBlockHallHome = (params?: UseQueryOptions<IBlockHallHome[]>) => {
    return useQuery<IBlockHallHome[]>([BLOCK_HALL_HOME], () => BlockService.getBlockHallHome(), {
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        ...params
    });
};
