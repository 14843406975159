import { useEffect, useState } from 'react';
import { useCart } from '../../../hooks';
import { AuthService, CartService, ReactGa4Service, RouterService } from '../../../services';
import authService from '../../../services/auth';
import { Cart, CartItem, CartItemWithElastic } from '../../../services/cart/types';
import { queryClient } from '../../../services/query-client';
import { getIsBreakPoint } from '../../../utils/get-config-tailwind';
import { CART } from '../../../utils/react-query-keys';
import { addHash, removeHash } from '../../../utils/string';
import { variantsSm } from '../../atoms/Modal/styles';
import ModalCheckout from '../../templates/Modal-checkout';
import ModalCheckoutProductOutStock from '../../templates/Modal-checkout-product-out-stock';
import { Footer, Header, Modal, ProductsListContainer, variants } from './styles';

import IconCart from '../../atoms/icons/Cart';
import { Button } from '../../molecules';
import { IModalCart } from './types';

import { motion } from 'framer-motion';
import { useRouter } from 'next/router';
import { useBlockConfig } from '../../../hooks/use-block-config';
import { useEstablishment } from '../../../hooks/use-establishment';
import useEstimateShipping from '../../../hooks/use-estimete-shipping';
import { IProducItems } from '../../../services/react-ga4/types';
import { currencyBrl } from '../../../utils/getFormattedValue';
import Alert from '../../molecules/Alert';
import CartItemComponent from '../../molecules/CartItem';
import { ICartItemVariant } from '../../molecules/CartItem/types';
import ZipCode from '../../templates/Product-details/components/Zip-code';

const checkProductsAvailable = () => {
    const cart = queryClient.getQueryData([CART]) as Cart;

    if (!cart) return;

    return cart.items.filter((item) => typeof item?.available !== 'undefined' && item.available === false);
};

interface SellerGroup {
    seller: string;
    products: CartItemWithElastic[];
}

const ModalCart = ({ onClose }: IModalCart) => {
    const [isOpen, setIsOpen] = useState(true);
    const [isOpenCheckout, setIsOpenCheckout] = useState(false);
    const [showModalProductOutStock, setShowModalProductOutStock] = useState<CartItem[]>([]);
    const isMd = getIsBreakPoint('md');
    const { data: establishment } = useEstablishment();
    const router = useRouter();
    const { data: cartData, isLoading: isLoadingCart } = useCart();
    const { data: config } = useBlockConfig({ refetchOnMount: false });
    const [hasFired, setHasFired] = useState<boolean>(false);

    const handleContinue = () => {
        setShowModalProductOutStock([]);
        const token = AuthService.getUserTokenStorage();
        if (token) {
            setIsOpen(false);
            removeHash('list-cart');
            return setIsOpenCheckout(true);
        }
        removeHash('list-cart');
        addHash('checkout');
        authService.setLastUrl();
        setIsOpen(false);
        RouterService.toRoute({ options: { url: '/entrar' } });
    };

    const handlePressContinue = () => {
        // ReactGa4Service.onBeginCheckout();
        const productOutStock = checkProductsAvailable();

        if (productOutStock?.length) {
            return setShowModalProductOutStock(productOutStock);
        }

        handleContinue();
    };

    useEffect(() => {
        if (isOpen) addHash('list-cart');

        return () => {
            removeHash('list-cart');
        };
    }, [isOpen]);

    const { data: shippingValue, isLoading: loadingShipping } = useEstimateShipping();

    const ordersBySeller: SellerGroup[] =
        cartData?.items.reduce((acc: SellerGroup[], item: CartItemWithElastic) => {
            let sellerGroup = acc.find((group) => group.seller === item.seller_name);

            if (sellerGroup) {
                sellerGroup.products.push(item);
            } else {
                acc.push({
                    seller: item.seller_name,
                    products: [item]
                });
            }

            return acc;
        }, []) || [];

    if (isOpen) {
        let items: IProducItems[] = [];
        const result = ordersBySeller.map((order) => {
            order.products.map((product) => {
                items.push({
                    item_id: product.sku,
                    item_name: product.name,
                    price: product.price,
                    quantity: product.qty
                });
            });
        });

        if (!hasFired) {
            const valueDatalayer = cartData?.info.grand_total ?? 0;
            ReactGa4Service.onViewCart(items, Number(valueDatalayer));
            setHasFired(true);
        }
    }

    const isLoadingValues = loadingShipping || isLoadingCart;

    const unavailableProducts = CartService.getProductsUnavailableCart();

    return (
        <>
            {isOpen && (
                <Modal variants={isMd ? variants : variantsSm} onClose={onClose}>
                    <div className="flex items-center mb-4">
                        <IconCart />
                        <Header
                            title="Carrinho"
                            showIconClose
                            onPressIconClose={onClose}
                            className="items-center justify-between w-full -ml-2"
                        />
                    </div>
                    <div className="mb-4">
                        <ZipCode />
                    </div>
                    <ProductsListContainer className="pr-1">
                        <motion.div className="flex flex-col gap-4">
                            {ordersBySeller.map((order, index) => (
                                <motion.div
                                    key={index}
                                    layoutId={`order-${order.seller}`}
                                    layout
                                    className="flex relative flex-col gap-4 border-[0.5px] border-[#DFDFDF] p-4 rounded-8"
                                >
                                    <motion.div layout="position">
                                        <h1 className="text-12 text-[#636363]">
                                            Vendido por{' '}
                                            <span className="text-[#9FBE43] font-bold">
                                                {order.seller?.toUpperCase() || 'MATCON.CASA'}
                                            </span>{' '}
                                            e entregue por <span className="text-[#9FBE43] font-bold">MATCON.CASA</span>
                                        </h1>
                                    </motion.div>
                                    <div className="flex flex-col">
                                        {order.products.map((product, index) => (
                                            <motion.div
                                                key={product.item_id}
                                                layoutId={`order-item-${product.item_id}}`}
                                                layout="position"
                                            >
                                                <CartItemComponent data={product} variant={ICartItemVariant.DEFAULT} />
                                                {index < order.products.length - 1 && (
                                                    <div className="h-[0.5px] bg-[#E6E6E6] my-4"></div>
                                                )}
                                            </motion.div>
                                        ))}
                                    </div>
                                </motion.div>
                            ))}
                        </motion.div>
                    </ProductsListContainer>
                    <Footer>
                        {/* <ModalCartFooter onPressContinue={handlePressContinue} /> old footer */}
                        <div className="flex flex-col px-2 mb-12">
                            <div className="flex justify-between mb-4">
                                <p>Frete a partir de</p>
                                <p>
                                    {isLoadingValues ? (
                                        <div className="bg-gray rounded-5 h-6 w-[130px] animate-pulse"></div>
                                    ) : (
                                        `R$ ${currencyBrl(shippingValue ?? 0)}`
                                    )}
                                </p>
                            </div>
                            {cartData?.info?.discount_amount != undefined && cartData?.info?.discount_amount < 0 && (
                                <div className="flex justify-between mb-4 text-primary">
                                    <p>Descontos</p>
                                    <p>
                                        {isLoadingValues ? (
                                            <div className="bg-gray rounded-5 h-6 w-[130px] animate-pulse"></div>
                                        ) : (
                                            `R$ ${currencyBrl(cartData?.info.discount_amount ?? 0)}`
                                        )}
                                    </p>
                                </div>
                            )}
                            <div className="flex justify-between mb-2 font-bold text-18">
                                <p>TOTAL</p>
                                {isLoadingValues ? (
                                    <div className="bg-gray rounded-5 h-6 w-[130px] animate-pulse"></div>
                                ) : (
                                    `R$ ${currencyBrl(cartData?.info.subtotal ?? 0)} à vista`
                                )}
                            </div>
                            {/*<span className="flex flex-col text-12">
                                <p className="self-end">no PIX ou Boleto</p>
                                <p className="self-end">
                                    ou R$ {currencyBrl(cartData?.info.subtotal ?? 0)} em até 10x sem Juros
                                </p>
                            </span>*/}
                        </div>
                        {establishment?.list.length == 0 && (
                            <div className="mb-4">
                                <Alert>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: (config?.carrinho_cep_sem_cobertura as any) || ''
                                        }}
                                    ></div>
                                </Alert>
                            </div>
                        )}
                        {unavailableProducts.length > 0 && (
                            <div className="mb-4">
                                <Alert>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: 'Existem produtos sem estoque, por favor remova-os do carrinho para continuar.'
                                        }}
                                    ></div>
                                </Alert>
                            </div>
                        )}

                        <Button
                            text="Ver Detalhes"
                            onClick={() => {
                                RouterService.toRoute({ options: { url: '/carrinho' } });
                            }}
                            disabled={
                                !cartData ||
                                cartData?.totalItems == 0 ||
                                establishment?.list.length == 0 ||
                                unavailableProducts.length > 0
                            }
                            style="border-radius: 32px; width: 100%;"
                        />
                    </Footer>
                </Modal>
            )}
            {/* <ModalPortal> */}
            {isOpenCheckout && <ModalCheckout onClose={onClose} />}
            {showModalProductOutStock.length > 0 && (
                <ModalCheckoutProductOutStock
                    data={showModalProductOutStock}
                    onPressConfirm={handleContinue}
                    onPressClose={() => setShowModalProductOutStock([])}
                />
            )}
            {/* </ModalPortal> */}
        </>
    );
};
ModalCart.displayName = 'ModalCart';
export default ModalCart;
