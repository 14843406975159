import { IconButton } from '../../../atoms';
import { IconButtonVariant } from '../../../atoms/Icon-button';
import { Container, Icon } from './styles';

interface IProductShoppingListProps {
    sku?: string;
    selectedProduct?: (sku: string) => void;
}

function ProductShoppingList({ sku, selectedProduct }: IProductShoppingListProps) {
    const handleClick = (e: any) => {
        e.preventDefault();

        if (selectedProduct && sku) {
            selectedProduct(sku);
        }
        e.stopPropagation();
    };

    return (
        <Container onClick={handleClick}>
            <IconButton variant={IconButtonVariant.Secondary}>
                <Icon />
            </IconButton>
        </Container>
    );
}

export default ProductShoppingList;
