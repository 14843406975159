import { useQuery } from '@tanstack/react-query';
import Image from 'next/image';
import useOnClickBanner from '../../../../hooks/use-on-click-banner';
import { BannerService, WishlistService } from '../../../../services';
import { NEXT_PUBLIC_HOME_BANNER_PRODUCTS } from '../../../../utils/envs';
import { BANNER } from '../../../../utils/react-query-keys';
import { ProductItem } from '../../../molecules';
import LayoutFitter from '../../Layout-fitter';
import { BannerContainer, Container, Content, Products, Title } from './styles';
import IncludeItemWishlist from '../../../molecules/Include-item-wishlist';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ModalPortal from '../../../atoms/Modal-portal';
import { IWishlist } from '../../../../services/wishlist/types';
import { useUserLogged } from '../../../../hooks';
import { useRouter } from 'next/router';

function HomeBannerProducts() {
    const [showModalWishlist, setShowModalWishlist] = useState<boolean>(false);
    const [selectedProduct, setSelectedProduct] = useState<string | null>(null);
    const [hasPostRequestFired, setHasPostRequestFired] = useState(false);
    const banner = useOnClickBanner();

    const router = useRouter();
    const { data: isLogged } = useUserLogged();

    const { data } = useQuery(
        [BANNER, NEXT_PUBLIC_HOME_BANNER_PRODUCTS],
        () => BannerService.getBannerWithProducts(NEXT_PUBLIC_HOME_BANNER_PRODUCTS),
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false
        }
    );
    if (!data?.products?.length) return null;

    function handleClickBanner() {
        if (data) {
            banner({
                type: data.type,
                param: data.paramDestiny,
                image: '',
                skuHighlight: []
            });
        }
    }

    const handleClickModal = async () => {
        if (!isLogged && selectedProduct && selectedProduct !== null) {
            localStorage.setItem('wish-p-id', selectedProduct);
            localStorage.setItem('prev-url', window.location.pathname);
            localStorage.setItem('wish-origin', 'banner');

            setSelectedProduct(null);
            setShowModalWishlist(false);
            router.push('/login');

            return;
        }

        localStorage.removeItem('wish-p-id');
        localStorage.removeItem('prev-url');
        localStorage.removeItem('wish-origin');

        const wishlistId = await findDefaultWishlist();

        if (wishlistId) {
            setHasPostRequestFired(false);
            toast.success(
                <div className="flex flex-row justify-between">
                    <span>Adicionado aos Meus Favoritos</span>
                    <a
                        onClick={() => {
                            setShowModalWishlist(true);
                            setHasPostRequestFired(true);
                        }}
                    >
                        INCLUIR NA LISTA
                    </a>
                </div>,
                {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: true,
                    className: 'md:w-[500px] text-sm',
                    onClose: () => {
                        if (!showModalWishlist && !hasPostRequestFired) {
                            addToDefaultWishlist(wishlistId);
                            setHasPostRequestFired(true);
                        }
                    }
                }
            );
        }
    };

    const findDefaultWishlist = async () => {
        const response = await WishlistService.all();
        const wishlist = response.find((item: IWishlist) => item.wishlist_name === 'My Wishlist' || 'Principal');
        return wishlist ? wishlist.wishlist_id : null;
    };

    const addToDefaultWishlist = async (wishlistId: any) => {
        if (!showModalWishlist) {
            try {
                await WishlistService.addItem(Number(wishlistId), Number(selectedProduct));
                setSelectedProduct(null);
            } catch (error: any) {
                if (error.response.data.message?.toLowerCase().includes('without stock')) {
                    toast.error(
                        'Não foi possível adicionar o produto à lista de compras, pois está sem estoque no momento'
                    );
                } else {
                    toast.error(error.response.data.message);
                }
            }
        }
    };

    useEffect(() => {
        if (selectedProduct && selectedProduct !== null) {
            handleClickModal();
        }
    }, [selectedProduct]);

    useEffect(() => {
        setSelectedProduct(null);
        setShowModalWishlist(false);
        const productId = localStorage.getItem('wish-p-id');
        const lastUrl = localStorage.getItem('prev-url');
        const origin = localStorage.getItem('wish-origin');
        if (productId && lastUrl && origin == 'banner') {
            setSelectedProduct(productId);
        }
    }, []);

    return (
        <>
            <LayoutFitter>
                <Container>
                    <Title>{data.title}</Title>
                    <Content>
                        <Products>
                            {data?.products?.map((product, i) => (
                                <div
                                    className={`w-full  [a:w-full]${
                                        data.products && i == data?.products?.length - 1
                                            ? 'flex md:hidden 2xl:flex'
                                            : ''
                                    }`}
                                    key={product.id}
                                >
                                    <ProductItem data={product} selectedProduct={setSelectedProduct} />
                                </div>
                            ))}
                        </Products>
                        <BannerContainer
                            type="button"
                            onClick={() => {
                                handleClickBanner();
                            }}
                        >
                            <Image
                                alt="banner"
                                className=" object-contain md:hidden"
                                fill
                                sizes="auto"
                                src={data?.imageMobile as string}
                            />
                            <Image
                                alt="banner"
                                className="object-contain hidden md:block"
                                fill
                                sizes="auto"
                                src={data?.image as string}
                            />
                        </BannerContainer>
                    </Content>
                </Container>
            </LayoutFitter>
            <ModalPortal>
                {showModalWishlist && (
                    <IncludeItemWishlist
                        onClose={() => setShowModalWishlist(false)}
                        onConfirm={() => setHasPostRequestFired(true)}
                        product={selectedProduct}
                    />
                )}
            </ModalPortal>
        </>
    );
}

export default HomeBannerProducts;
