import { AuthService, UserService } from "..";
import api from "../api";

const addNewsletter = async (emailGuest: string) => {
  const [token] = await Promise.all([AuthService.getUserTokenStorage()]);
  const endpoint = token ? `/V1/newsletter/customer/subscribe` : `/V1/newsletter/guest/subscribe`;
  const userInfo = token ? await UserService.getInfo() : null;

  // Configurar headers condicionalmente
  const headers: Record<string, string> = token
    ? { Authorization: `Bearer ${token}` }
    : {};

  const response = await api.post(
    endpoint,
    {
      email: emailGuest,
    },
    {
      headers,
    }
  );

  return response;
};

export default {
  addNewsletter,
};
