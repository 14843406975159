import axios, { AxiosRequestConfig } from 'axios';
import { ICookieCtx } from '../../utils/cookie';
import api, { apiNext } from '../api';
import CookieService from '../cookie';
import { ICheckCoverageEstablishmentResponse, IEstablishment, IEstablishmentItem } from './types';
import { formatZipCode } from '../../utils/string';
import { CART, ESTABLISHMENT, HALL } from '../../utils/react-query-keys';
import { HALL_FEATURED_TITLE } from '../../components/organisms/Hall-products-featured/types';
import { queryClient } from '../query-client';

async function checkCoverageEstablishment(
    zipCode: string,
    axiosParams?: AxiosRequestConfig
): Promise<IEstablishmentItem[]> {
    const { data } = await api.post<ICheckCoverageEstablishmentResponse[] | string>(
        `V1/matcon/buscar-faixa-cep`,
        {
            data: {
                cep: zipCode
            }
        },
        axiosParams
    );
    if (data === 'false') return [];
    if (!Array.isArray(data)) return [];

    return data.map((establishment) => ({
        storeId: Number(establishment.store_id),
        code: establishment.code,
        websiteId: Number(establishment.website_id),
        isCnpjAvailable: establishment.is_cnpj_available,
        message: establishment.mensagem
    }));
}

async function checkEstablishment(ip: string) {
    const establishment = await getEstablishmentsStorage();

    if ((establishment?.zipCode && establishment?.zipCode?.length > 0) || !ip) {
        return;
    }

    const { data } = await apiNext.post(`/region`, [{ query: ip }]);

    if (!data || !data.length) return;
    const zipCode = formatZipCode(data[0].zip.toString().padEnd(8, '0'));

    const establishments = await checkCoverageEstablishment(zipCode);

    setEstablishmentStorage({
        list: establishments,
        zipCode,
        region: data[0].regionName,
        showAlertModal: true
    });
}

async function setEstablishmentStorage(store: IEstablishment | null, options?: ICookieCtx) {
    CookieService.setEstablishmentStorage(store, options);

    await queryClient.invalidateQueries([HALL, HALL_FEATURED_TITLE]);
    await queryClient.resetQueries([ESTABLISHMENT]);
    CookieService.setCartIdStorage(null), await queryClient.resetQueries([CART]);
}

async function getEstablishmentsStorage(params?: ICookieCtx) {
    return CookieService.getEstablishmentStorage(params);
}

const createUrl = (url: string) => {
    return `/${url}`;
};

export default {
    checkCoverageEstablishment,
    createUrl,
    getEstablishmentsStorage,
    checkEstablishment,
    setEstablishmentStorage
};
