import { useEffect } from 'react';

export default function useAdoptCallbacks(onAccept: () => void, onReject: () => void) {
    useEffect(() => {
        if (typeof window !== 'undefined') {
            const observer = new MutationObserver(() => {
                const acceptButton = document.getElementById('adopt-accept-all-button');
                const rejectButton = document.getElementById('adopt-reject-all-button');

                let buttonsFound = false;

                if (acceptButton) {
                    acceptButton.addEventListener('click', () => {
                        console.log('Clique no botão Aceitar Todos detectado.');
                        onAccept();
                    });
                    buttonsFound = true;
                }

                if (rejectButton) {
                    rejectButton.addEventListener('click', () => {
                        console.log('Clique no botão Rejeitar Todos detectado.');
                        onReject();
                    });
                    buttonsFound = true;
                }

                // Se pelo menos um dos botões foi encontrado, podemos desconectar o observador
                if (buttonsFound) {
                    observer.disconnect();
                }
            });

            // Configura o observador para monitorar adições ao DOM
            observer.observe(document.body, {
                childList: true,
                subtree: true
            });

            // Limpa o observador quando o componente for desmontado
            return () => {
                observer.disconnect();
            };
        }
    }, []);
}
