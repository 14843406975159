import { AnimatePresence } from 'framer-motion';
import dynamic from 'next/dynamic';
import { memo, useEffect, useState } from 'react';
import { useBreakpointTailwind } from '../../../hooks';
import { useEstablishment } from '../../../hooks/use-establishment';
import { EstablishmentService } from '../../../services';
import { Logo } from '../../atoms';
import { LogoColor } from '../../atoms/Logo';
import ModalPortal from '../../atoms/Modal-portal';
import { LayoutFitter, ModalChangeRegion } from '../../templates';
import UpperHeaderBanner from '../Upper-header-banner';
import AlertRegion from './Alert-region';
import Categories from './Categories';
import ContentMobile from './ContentMobile';
import InputSearch from './Input-search';
import Order from './Order';
import Region from './Region';
import { ContentDesktop, Right } from './styles';
import AlertCnpjRegion from './Alert-cnpj';
import Link from 'next/link';
import { motion } from 'framer-motion';

const Cart = dynamic(() => import('./Cart'), { ssr: false });
const UserIdentification = dynamic(() => import('./User-identification'), { ssr: false });

interface IHeaderProps {}

const Header = ({}: IHeaderProps) => {
    const [isShowAlertRegion, setIsShowAlertRegion] = useState(false);
    const [isOpenChangeRegionModal, setIsOpenChangeRegionModal] = useState(false);

    const { data: establishment } = useEstablishment();

    // const isMd = useBreakpointTailwind('md');

    // useEffect(() => {
    //         setIsShowAlertRegion(true);
    // }, [isMd]);

    const onCloseChangeRegion = () => {
        if (establishment?.zipCode) {
            return;
        }
        setIsShowAlertRegion(true);
    };
    const onClickAlertRegion = () => {
        setIsOpenChangeRegionModal(true);
    };

    return (
        <>
            <UpperHeaderBanner />
            <motion.header layout="preserve-aspect" className="sticky top-0 z-[5] bg-white">
                <LayoutFitter disabledOverflow>
                    <ContentDesktop>
                        <Link href={EstablishmentService.createUrl('')}>
                            <Logo color={LogoColor.PRIMARY} widthPortrait={200} heightPortrait={56} />
                        </Link>
                        <InputSearch />
                        <Right>
                            <Region
                                onClickChangeRegion={onClickAlertRegion}
                                onCloseChangeRegion={onCloseChangeRegion}
                            />
                            <UserIdentification />
                            <Order />
                            <Cart />
                        </Right>
                    </ContentDesktop>
                </LayoutFitter>
                <ContentMobile onClickChangeRegion={onClickAlertRegion} onCloseChangeRegion={onCloseChangeRegion} />
                <Categories />
                <AnimatePresence>
                    {establishment?.list?.length == 0 && <AlertRegion onClick={onClickAlertRegion} />}
                </AnimatePresence>

                <AnimatePresence>{<AlertCnpjRegion />}</AnimatePresence>
            </motion.header>
            <ModalPortal>
                {isOpenChangeRegionModal && <ModalChangeRegion onClose={() => setIsOpenChangeRegionModal(false)} />}
            </ModalPortal>
        </>
    );
};

export default memo(Header);
