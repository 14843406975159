import { memo, useEffect, useState } from 'react';
import Hall from '../Hall';
import { createFeaturedHallFilter, HALL_FEATURED_FILTER, HALL_FEATURED_TITLE } from './types';
import { toast } from 'react-toastify';
import { WishlistService } from '../../../services';
import { IWishlist } from '../../../services/wishlist/types';
import ModalPortal from '../../atoms/Modal-portal';
import IncludeItemWishlist from '../../molecules/Include-item-wishlist';
import { useUserLogged } from '../../../hooks';
import { useRouter } from 'next/router';
import { useBlockConfig } from '../../../hooks/use-block-config';
import { IBlockConfig } from '../../../services/block/types';
import { IBlockHallHome } from '../../../services/block';
// const currentDateFilter = format(new Date(), `Y-MM-dd`, { locale: ptBR });

// export const HALL_FEATURED_FILTER = {
//     bool: {
//         filter: [
//             {
//                 term: {
//                     status_stock: 1
//                 }
//             },
//             { condition_type: ConditionType.EQ, field: 'featured', value: 1 }
//         ]
//     }
// };

interface IHall {
    origin: string;
    id?: string;
    hallHome: IBlockHallHome;
}

const HallProductsFeatured = ({ origin, id, hallHome }: IHall) => {
    const [showModalWishlist, setShowModalWishlist] = useState<boolean>(false);
    const [selectedProduct, setSelectedProduct] = useState<string | null>(null);
    const [product, setProduct] = useState<string | null>(null);
    const [hasPostRequestFired, setHasPostRequestFired] = useState(false);
    const router = useRouter();
    const { data: isLogged } = useUserLogged();

    const title = hallHome.title;
    const categories = hallHome.categories;

    useEffect(() => {
        if (selectedProduct && selectedProduct !== null) {
            handleClickModal(selectedProduct);
        }
    }, [selectedProduct]);

    useEffect(() => {
        const productId = localStorage.getItem('wish-p-id');
        const lastUrl = localStorage.getItem('prev-url');
        const originStoraged = localStorage.getItem('wish-origin');

        if (productId && lastUrl && origin == originStoraged) {
            handleClickModal(productId);
        }
    }, []);

    const handleLocalStorage = (action: 'set' | 'remove', productId?: string, origin?: string) => {
        if (action === 'set' && productId && origin) {
            localStorage.setItem('wish-p-id', productId);
            localStorage.setItem('prev-url', window.location.pathname);
            localStorage.setItem('wish-origin', origin);
        } else {
            localStorage.removeItem('wish-p-id');
            localStorage.removeItem('prev-url');
            localStorage.removeItem('wish-origin');
        }
    };

    const handleClickModal = async (productId: any) => {
        if (!isLogged && selectedProduct) {
            handleLocalStorage('set', selectedProduct, origin);
            router.push('/login');
            return;
        }

        handleLocalStorage('remove');

        const wishlistId = await findDefaultWishlist();
        if (wishlistId) {
            setHasPostRequestFired(false);
            toast.success(
                <div className="flex flex-row justify-between">
                    <span>Adicionado aos Meus Favoritos</span>
                    <a
                        onClick={() => {
                            setProduct(productId);
                            setShowModalWishlist(true);
                            setHasPostRequestFired(true);
                        }}
                    >
                        INCLUIR NA LISTA
                    </a>
                </div>,
                {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: true,
                    className: 'md:w-[500px] text-sm',
                    onClose: () => {
                        if (!showModalWishlist && !hasPostRequestFired) {
                            addToDefaultWishlist(wishlistId, productId);
                            setHasPostRequestFired(true);
                        }
                    }
                }
            );
        }
    };

    const findDefaultWishlist = async () => {
        const response = await WishlistService.all();
        const wishlist = response.find((item: IWishlist) => item.wishlist_name === 'My Wishlist' || 'Principal');
        return wishlist ? wishlist.wishlist_id : null;
    };

    const addToDefaultWishlist = async (wishlistId: any, productId: any) => {
        if (!showModalWishlist) {
            try {
                await WishlistService.addItem(Number(wishlistId), Number(productId));
                setSelectedProduct(null);
            } catch (error: any) {
                if (error.response.data.message?.toLowerCase().includes('without stock')) {
                    toast.error(
                        'Não foi possível adicionar o produto à lista de compras, pois está sem estoque no momento'
                    );
                } else {
                    toast.error(error.response.data.message);
                }
            }
        }
    };

    return (
        <>
            <Hall
                title={title}
                id={id}
                filterGroups={createFeaturedHallFilter(categories)}
                selectedProduct={setSelectedProduct}
                sort={categories.map((item) => ({
                    [`position_category_${item}`]: {
                        order: 'asc'
                    }
                }))}
            />
            <ModalPortal>
                {showModalWishlist && (
                    <IncludeItemWishlist
                        onClose={() => setShowModalWishlist(false)}
                        onConfirm={() => setHasPostRequestFired(true)}
                        product={product}
                    />
                )}
            </ModalPortal>
        </>
    );
};

export default memo(HallProductsFeatured);
