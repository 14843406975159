import { motion } from 'framer-motion';
import tw, { styled } from 'twin.macro';
import { Image } from '../../atoms';

export const Container = styled(motion.div)<{ $forceWidth: boolean }>`
    ${tw`
        flex
        flex-col
        gap-4
        border-[0.5px]
        border-[#DADADA]
        p-4
        relative
        // min-h-[352px]
        rounded-12
        overflow-hidden
        md:rounded-16
    `}
    ${({ $forceWidth }) => $forceWidth && tw`w-[176px] md:w-[224px]`}
`;

export const ImageContainer = styled(motion.div)`
    ${tw`
        relative
      
        w-full
        h-[150px]
        md:h-48
    `}
`;

export const Img = styled(Image)`
    ${tw`
       object-cover
    `}
`;

export const Content = styled.div`
    ${tw`
        flex
        flex-col
        gap-2
    `}
`;

export const PriceFrom = styled.div`
    ${tw`
        text-black
        font-light
        flex
        items-center
        line-through
        text-10
        min-h-[12.5px]
        md:text-12
        md:min-h-[16px]
    `}
`;
export const Price = styled.div`
    ${tw`
        text-black
        font-bold
        flex
        items-center
        text-18
        min-h-[32px]
        md:min-h-[25px]
        md:text-22
    `}
`;

export const Installments = styled.div`
    ${tw`
        font-light
        text-black
        text-10
        md:text-12
        min-h-[16px]
    `}
`;
