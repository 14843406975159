import Image from 'next/image';
import { Column, Menu, PaymentList, Title } from '../styles';

interface IPaymentsProps {
    title?: boolean;
}
function Payments({ title = false }: IPaymentsProps) {
    return (
        <Column>
            {title && <Title>Formas de Pagamento</Title>}
            <PaymentList>
                <Image
                    loading="lazy"
                    placeholder="blur"
                    src="/images/brands-cards/visa.png"
                    blurDataURL="/images/brands-cards/visa.png"
                    alt="Visa"
                    width={43}
                    height={27}
                />
                <Image
                    placeholder="blur"
                    loading="lazy"
                    src="/images/brands-cards/mastercard.png"
                    blurDataURL="/images/brands-cards/mastercard.png"
                    alt="MasterCard"
                    width={43}
                    height={27}
                />

                <Image
                    placeholder="blur"
                    loading="lazy"
                    src="/images/brands-cards/pix.png"
                    blurDataURL="/images/brands-cards/pix.png"
                    alt="PIX"
                    width={43}
                    height={27}
                />
                <Image
                    placeholder="blur"
                    loading="lazy"
                    src="/images/brands-cards/american.png"
                    blurDataURL="/images/brands-cards/american.png"
                    alt="AmericanExpress"
                    width={43}
                    height={27}
                />

                <Image
                    placeholder="blur"
                    loading="lazy"
                    src="/images/brands-cards/boleto.png"
                    blurDataURL="/images/brands-cards/boleto.png"
                    alt="Boleto"
                    width={43}
                    height={27}
                />
            </PaymentList>
        </Column>
    );
}

export default Payments;
