import { AxiosRequestConfig } from 'axios';
import { format as formatDate } from 'date-fns';
import qs from 'qs';
import { ICookieCtx } from '../../utils/cookie';
import {
    NEXT_PUBLIC_CLIENT,
    NEXT_PUBLIC_STORE_DEFAULT_CODE_ID,
    NEXT_PUBLIC_URL_API,
    NEXT_PUBLIC_URL_IMAGES
} from '../../utils/envs';
import getFormattedValue from '../../utils/getFormattedValue';
import { dynamicSort } from '../../utils/string';
import api, { apiAdminPublic, apiNext } from '../api';
import axios from '../axios';
import { CartItemProductOption } from '../cart/types';
import ElasticService from '../elastic';
import cookieService from '../cookie';
import { ConditionType } from '../magento/types';
import {
    EProductTypeUnit,
    IProduct,
    IProductAlertStock,
    IProductEstimateShipping,
    ProductBanner,
    ProductTierPrices
} from '../product/types';
import { parse as parseHimalaya, stringify } from 'himalaya';
import { IElasticItem } from '../elastic/types';

const get = async (sku: string, params?: any): Promise<IProduct> => {
    const { data } = await apiNext.get(`product/${sku}`, {
        paramsSerializer: (p) => {
            return qs.stringify(p, { arrayFormat: 'indices' });
        },
        ...params
    });

    return data ? format(data) : data;
};
const getByUrlKey = async (
    urlKey: string,
    params?: AxiosRequestConfig,
    cookiesParams?: ICookieCtx
): Promise<IProduct | undefined> => {
    const products = await ElasticService.getProducts({
        filter: {
            bool: {
                must: [
                    {
                        multi_match: {
                            query: urlKey,
                            fields: ['url_key']
                        }
                    }
                ]
            }
        },
        limit: 1,
        paramsAxios: params,
        cookiesParams
    });

    if (products && products.items.length) {
        return products.items[0];
    }
};

const getById = async (id: number | string, params?: AxiosRequestConfig): Promise<IProduct> => {
    // const { data } = await apiAdminPublic.get(`V1/products`, {
    //     paramsSerializer: (p) => {
    //         return qs.stringify(p, { arrayFormat: 'indices' });
    //     },
    //     params: {
    //         searchCriteria: {
    //             filterGroups: [
    //                 {
    //                     filters: [{ field: 'entity_id', value: id, condition_type: ConditionType.EQ }]
    //                 }
    //             ]
    //         }
    //     },
    //     ...params
    // });
    // if (data && data.items.length) {
    //     data.items = filterInStock(data.items);
    //     return format(data.items[0]);
    // }
    // return data;
    const { data } = await apiNext.get(`products`, {
        paramsSerializer: (p) => {
            return qs.stringify(p, { arrayFormat: 'indices' });
        },
        params: {
            searchCriteria: {
                filterGroups: [
                    {
                        filters: [{ field: 'entity_id', value: id, condition_type: ConditionType.EQ }]
                    }
                ]
            }
        },
        ...params
    });
    if (data && data.items.length) {
        return format(data.items[0]);
    }
    return data;
};

const getAllBySku = async (skus: string[], params?: AxiosRequestConfig) => {
    return await ElasticService.getProducts({
        filter: {
            bool: {
                must: [
                    {
                        query_string: {
                            query: skus.join('  OR '),
                            fields: ['sku']
                        }
                    }
                ]
            }
        },
        paramsAxios: params
    });
};

const getAllById = async (id: number[] | string[], params?: AxiosRequestConfig): Promise<IProduct[]> => {
    const { data } = await apiNext.get(`product`, {
        paramsSerializer: (p) => {
            return qs.stringify(p, { arrayFormat: 'indices' });
        },
        params: {
            searchCriteria: {
                filter_groups: [
                    {
                        filters: [{ field: 'entity_id', value: id.join(','), condition_type: ConditionType.IN }]
                    }
                ]
            }
        },
        ...params
    });

    if (data && data.items.length) {
        return data.items;
    }
    return data;
};

const getValueBefore = (product: IProduct): number | undefined => {
    if (!product) {
        return undefined;
    }
    const exists = product?.custom_attributes.find((custom) => custom.attribute_code == 'special_price');

    if (exists) {
        return Number(exists.value);
    }
};

const getTierCurrent = (qty: number, tierPrice?: ProductTierPrices[]): ProductTierPrices | undefined => {
    if (!tierPrice) {
        return undefined;
    }

    const valids = tierPrice.filter((tier) => qty >= tier.qty).sort(dynamicSort('qty'));
    if (valids && valids.length) {
        const valid = valids[valids.length - 1];

        valid.value = parseFloat(valid.value.toFixed(2));
        return valid;
    }
};
const getTierNext = (qty: number, tierPrice?: ProductTierPrices[]): ProductTierPrices | undefined => {
    if (!tierPrice) {
        return undefined;
    }
    const valid = tierPrice.sort(dynamicSort('qty')).find((tier) => qty < tier.qty);
    if (valid) {
        valid.value = parseFloat(valid.value.toFixed(2));
        return valid;
    }
};

const getValue = (product: IProduct, qty: number, checkWeight = false): number => {
    return product.price;
};

const getValueDisplay = (product: IProduct, qty: number, checkWeight = false) => {
    const productWeight = product.custom_attributes.find((c) => c.attribute_code == 'tipo_medida');
    // if (productWeight?.value == EProductTypeUnit.WEIGHT) {
    //     const value = product.custom_attributes.find((c) => c.attribute_code == 'price_kg')?.value || '';
    //     return `R$ ${getFormattedValue({ value, mask: 'currencyDecimal' })}kg`;
    // }

    try {
        return (
            'R$ ' +
            getFormattedValue({ value: getValue(product, qty, checkWeight).toString(), mask: 'currencyDecimal' })
        );
    } catch (e) {
        console.info(e, product);
        return 'R$ ---';
    }
};
const isProductWeight = (product: IProduct): boolean => {
    const productWeight = product.custom_attributes.find((c) => c.attribute_code == 'tipo_medida');

    return productWeight?.value == EProductTypeUnit.WEIGHT && product.weight != undefined;
};
const getQty = (product: IProduct, qty: number) => {
    const productWeight = product.custom_attributes.find((c) => c.attribute_code == 'tipo_medida');
    if (productWeight?.value == EProductTypeUnit.WEIGHT && product?.weight) {
        let newQty;
        // if (product.var_kg && qty > 1) {
        //     console.log(parseFloat(product.weight), product.var_kg, qty, product.var_kg * qty);
        //     newQty = parseFloat(product.weight) + product.var_kg * (qty - 1);
        // } else {
        newQty = parseFloat(product.weight) * qty;
        // }
        return `${getFormattedValue({
            mask: 'currencyDecimal',
            value: newQty.toString(),
            minimumFractionDigits: 3,
            maximumFractionDigits: 3
        })}kg`;
    }

    return qty;
};

const getUrlImage = (product: IProduct, image = 'small_image'): string => {
    // const exists: any = product?.custom_attributes.find((custom: any) => custom.attribute_code == image);

    // if (!exists) {
    //     return '';
    // }

    if (NEXT_PUBLIC_CLIENT === 'matcon') {
        return `${NEXT_PUBLIC_URL_IMAGES}/${product.sku}.jpg`;
    }
    return `${NEXT_PUBLIC_URL_IMAGES}${product.sku}/${product.sku}_base.jpg`;
};

const createUrlImage = (image: string) => {
    return `${NEXT_PUBLIC_URL_API}/media/catalog/product${image}`;
};

const getDateProduct = (date: string | null) => {
    let newDate = date;
    if (newDate) {
        [newDate] = newDate.split(' ');
    }
    return newDate;
};

const getPercentDiscountElastic = (
    specialPrice: number,
    originalPrice: number,
    specialFromDate?: string,
    specialToDate?: string
) => {
    const currentDate = formatDate(new Date().getTime(), 'yyyy-MM-dd');
    if (!specialPrice) {
        return {
            percentage: 0,
            priceBefore: null
        };
    }

    if (specialFromDate && specialFromDate > currentDate) {
        return {
            percentage: 0,
            priceBefore: null
        };
    }
    if (specialFromDate && specialToDate) {
        if (specialToDate) {
            if (specialToDate < currentDate) {
                // if (isBefore(new Date(specialToDate), currentDate)) {

                return {
                    percentage: 0,
                    priceBefore: null
                };
            }
            // } else if (isAfter(new Date(specialFromDate), currentDate)) {
        } else if (specialFromDate > currentDate) {
            return {
                percentage: 0,
                priceBefore: null
            };
        }
    }

    const before: any = Number(originalPrice);
    const after: any = Number(specialPrice);

    if (after > before) {
        return {
            percentage: 0,
            priceBefore: null
        };
    }
    const result = ((before - after) / before) * 100;

    return {
        percentage: result > 0 && result < 1 ? 1 : Math.trunc(result),
        priceBefore: Number(specialPrice)
    };
};

const getAttribute = (product: IProduct, attr: string) => {
    const exists = product && product.custom_attributes?.find((custom) => custom.attribute_code == attr);

    if (exists) return exists.value;

    return null;
};

const format = (product: IProduct): IProduct => {
    return {
        ...product,
        custom_attributes: product.custom_attributes
            ? Object.keys(product.custom_attributes).map((attr: any) => product.custom_attributes[attr])
            : []
    };
};

const getOptionsDefault = (product: IProduct): CartItemProductOption | undefined => {
    if (product?.options && product.options.length) {
        const [firstOption] = product.options;
        const [firstValue] = firstOption.values as any;
        return {
            extension_attributes: {
                configurable_item_options: [
                    {
                        option_id: firstOption?.option_id,
                        option_value: firstValue.option_type_id
                    }
                ]
            }
        };
    }
};

const filterInStock = (products: IProduct[]) => {
    // const establishment = EstablishmentService.getEstablishmentStorage();
    // const webSiteId = establishment?.webSiteId || 0;
    return products.filter(
        (item: IProduct) =>
            item.extension_attributes?.website_ids?.includes(0) &&
            item.extension_attributes?.stock_item?.is_in_stock &&
            item.extension_attributes.stock_item.qty
    );
};

const getAllAdmin = async (establishmentKey: string, params?: any) => {
    const apiAdmin = axios.getApiAdmin(establishmentKey);
    return apiAdmin.get('V1/products', {
        paramsSerializer: (p) => {
            return qs.stringify(p, { arrayFormat: 'indices' });
        },
        ...params
    });
};
const getAdmin = async (establishmentKey: string, sku: string, params?: any): Promise<IProduct> => {
    const apiAdmin = axios.getApiAdmin(establishmentKey);
    const { data } = await apiAdmin.get(`V1/products/${sku}`, {
        paramsSerializer: (p) => {
            return qs.stringify(p, { arrayFormat: 'indices' });
        },
        ...params
    });
    return data;
};

const isInStockAdmin = async (establishmentKey: string, sku: string, idStock: number) => {
    const apiAdmin = axios.getApiAdmin(establishmentKey);
    return apiAdmin.get(`V1/inventory/is-product-salable/${sku}/${idStock}`);
};

function getRouterProduct(data: IProduct) {
    const urlKey = data.custom_attributes.find((v) => v.attribute_code == 'url_key');
    if (!urlKey) return '';
    return `/produto/${urlKey.value}`;
}

const parseBannerProduct = (content: string): ProductBanner | null => {
    try {
        const parse = parseHimalaya(content);
        const table = parse.find((v: any) => v.tagName == 'table');
        if (!table) {
            return null;
        }
        const tbody = table.children.find((v: any) => v.tagName == 'tbody');
        if (!tbody) {
            return null;
        }
        const trs = tbody.children.filter((v: any) => v.tagName == 'tr');

        const bannerDesktop = trs[0].children.filter((v: any) => v.tagName == 'td').map((td: any) => td.children[0])[1];
        const bannerMobile = trs[1].children.filter((v: any) => v.tagName == 'td').map((td: any) => td.children[0])[1];

        const bannerSrcDesktop = bannerDesktop?.attributes
            ?.find((a: any) => a.key == 'src')
            ?.value.replace(/&quot;/g, '"')
            .match(/\"(.*?)\"/)[1];
        const bannerSrcMobile = bannerMobile?.attributes
            ?.find((a: any) => a.key == 'src')
            ?.value.replace(/&quot;/g, '"')
            .match(/\"(.*?)\"/)[1];

        return {
            bannerDesktop: bannerSrcDesktop ? `${NEXT_PUBLIC_URL_API}/media/${bannerSrcDesktop}` : null,
            bannerMobile: bannerSrcMobile ? `${NEXT_PUBLIC_URL_API}/media/${bannerSrcMobile}` : null
        };
    } catch (e) {
        console.error('parse images category', e);
        return null;
    }
};

async function alertStock(body: IProductAlertStock) {
    const establishment = cookieService.getEstablishmentStorage();
    const establishmentCodeId = establishment?.list?.[0]?.code || NEXT_PUBLIC_STORE_DEFAULT_CODE_ID;

    const { data } = await apiAdminPublic.post(`V1/bee/aviseme`, {
        data: {
            store_id: establishmentCodeId,
            nome: body.name,
            email: body.email,
            product_id: body.productId
        }
    });
    return data;
}

async function estimateShippingProduct(
    sku: string,
    qty: number,
    paramsRequest?: AxiosRequestConfig
): Promise<IProductEstimateShipping[]> {
    const establishment = cookieService.getEstablishmentStorage();
    const { data } = await api.post(
        `/V1/bee/shipping-product`,
        {
            data: {
                sku: sku,
                qty: qty,
                cep: establishment?.zipCode
            }
        },
        paramsRequest
    );

    return data.filter((v: any) => v.available);
}

const getUrlSearchProduct = (search?: string) => {
    return `/${search || ''}`;
};

const PRODUCTS_FIELDS =
    'id,product_links,sku,price,name,status,weight,media_gallery_entries,custom_attributes[marca,small_image,tipo_medida,price_kg,special_price,special_from_date,special_to_date,description,url_key,meta_title,meta_keyword,meta_description],options,tier_prices,extension_attributes';

export default {
    getAllAdmin,
    getAdmin,
    parseBannerProduct,
    isInStockAdmin,
    getUrlImage,
    getValueBefore,
    getValue,
    PRODUCTS_FIELDS,
    createUrlImage,
    get,
    getAttribute,
    format,
    getAllById,
    getOptionsDefault,
    getById,
    getQty,
    getTierNext,
    getByUrlKey,
    filterInStock,
    getValueDisplay,
    isProductWeight,
    getRouterProduct,
    getAllBySku,
    alertStock,
    estimateShippingProduct,
    getUrlSearchProduct,
    getPercentDiscountElastic
};
