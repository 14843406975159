import { motion } from 'framer-motion';
import Link from 'next/link';
import { ProductService } from '../../../services';
import { IProduct } from '../../../services/product/types';
import { NEXT_PUBLIC_URL_IMAGES } from '../../../utils/envs';
import { currencyBrl } from '../../../utils/getFormattedValue';
import { capitalizeEveryLetter } from '../../../utils/string';
import { ProductOutStock } from '../../atoms';
import ProductTags from '../Product-tags';
import ProductOffer from './Product-offer';
import ProductShoppingList from './Shopping-list';
import { Container, Content, ImageContainer, Img, Installments, Price, PriceFrom } from './styles';

interface ProductItemProps {
    data: IProduct;
    forceWidth?: boolean;
    selectedProduct?: (sku: string) => void;
    titleTag?: keyof JSX.IntrinsicElements;
}

function ProductItem({ data, forceWidth = false, selectedProduct, titleTag: TitleTag = 'div' }: ProductItemProps) {
    const percentDiscount = data?.discount_percentage;
    return (
        <Link href={ProductService.getRouterProduct(data)} passHref>
            <Container $forceWidth={forceWidth} initial="rest" whileHover="hover" animate="rest">
                <div className="relative">
                    <motion.div className="overflow-hidden rounded-8">
                        <ImageContainer
                            variants={{
                                hover: {
                                    scale: 1.1
                                }
                            }}
                        >
                            <Img
                                fill
                                sizes="auto"
                                className="object-cover"
                                src={`${NEXT_PUBLIC_URL_IMAGES}${data.ean}.jpg` || 'sem-image'}
                                forceHeight={true}
                                quality={50}
                                alt="product image"
                            />
                        </ImageContainer>
                    </motion.div>
                    {percentDiscount && percentDiscount > 0 && <ProductOffer value={percentDiscount} />}
                </div>
                <Content>
                    <TitleTag
                        className="line-clamp-2 font-semibold text-black text-12 min-h-[32px] md:min-h-[40px] md:text-14"
                        title={capitalizeEveryLetter(data.name)}
                    >
                        {capitalizeEveryLetter(data.name)}
                    </TitleTag>
                    <div>
                        <PriceFrom>
                            {data.special_price && data.special_price > 0 && `de R$ ${currencyBrl(data.special_price)}`}
                        </PriceFrom>

                        <Price>R$ {currencyBrl(ProductService.getValue(data, 1))}</Price>

                        <Installments>
                            {/* {data?.installments?.parcelas && (
                                <span>
                                    at {data.installments.parcelas}x R$ {currencyBrl(data.installments.valor)}
                                </span>
                            )} .*/}
                        </Installments>
                    </div>
                </Content>
                <ProductShoppingList sku={data.sku} selectedProduct={selectedProduct} />
                {!data.inStock && (
                    <ProductOutStock disableStopPropagation={false} showTrash={false} horizontal={false} />
                )}
                <ProductTags data={data} showAllOnMobile={false} />
            </Container>
        </Link>
    );
}

export default ProductItem;
