import tw, { styled } from 'twin.macro';

export const ContentDesktop = styled.div`
    ${tw`
        py-6
        hidden
        flex-row
        items-center
        gap-6
        md:flex
        md:px-[1%]
    `}
`;

export const Right = styled.div`
    ${tw`
        flex
        flex-row
        gap-6
        pr-6
        items-center
        justify-between
    `}
`;
