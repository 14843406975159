import tw, { styled } from 'twin.macro';
import { IconOrder } from '../../../atoms';

export const Container = styled.div`
    ${tw`
        absolute
        top-2
        right-2
    `}
`;

export const Icon = styled(IconOrder)`
    ${tw`
        h-6
        w-6
        text-[#9FBE43]
    `}
`;
