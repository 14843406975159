import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { ProductService } from '../../services';
import { IProduct } from '../../services/product/types';
import { PRODUCT_URL_KEY } from '../../utils/react-query-keys';

export const useProductUrlKey = (key: string, params?: UseQueryOptions<IProduct | undefined>) => {
    return useQuery<IProduct | undefined>([PRODUCT_URL_KEY, key], () => ProductService.getByUrlKey(key), {
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        ...params
    });
};
