import dynamic from 'next/dynamic';
import { IProduct, IProductLinksType } from '../../../../../services/product/types';
import { useMemo } from 'react';
import {
    NEXT_PUBLIC_PRODUCT_DETAIL_HALL_CROSS_SELL,
    NEXT_PUBLIC_PRODUCT_DETAIL_HALL_RELATED,
    NEXT_PUBLIC_PRODUCT_DETAIL_HALL_UP_SELL
} from '../../../../../utils/envs';

const HallComponent = dynamic(() => import('../../../../organisms/Hall'), { ssr: false });

const getFilterHall = (data: IProduct | undefined, type: IProductLinksType) => {
    const items = data?.product_links?.filter((v) => v.link_type == type);
    if (!data || !data?.product_links || !data.product_links.length || !items || !items?.length) return null;

    const skus = items.map((link) => link.linked_product_sku);
    return {
        bool: {
            must: [
                {
                    query_string: {
                        query: skus.join(' OR '),
                        fields: ['sku']
                    }
                }
                // {
                //     term: {
                //         status_stock: 1
                //     }
                // }
            ]
        }
    };
};

interface HallProps {
    data: IProduct;
}

function Hall({ data }: HallProps) {
    const filterHallCrossSell = useMemo(() => data && getFilterHall(data, 'crosssell'), [data]);
    const filterHallUpSell = useMemo(() => data && getFilterHall(data, 'upsell'), [data]);
    const filterHall = useMemo(() => data && getFilterHall(data, 'related'), [data]);

    return (
        <div className="pt-6 md:pt-12 flex flex-col gap-4">
            <>
                {filterHall && (
                    <HallComponent
                        filterGroups={filterHall}
                        skeletonQty={1}
                        horizontal={true}
                        useTitleInQuery={false}
                        title={NEXT_PUBLIC_PRODUCT_DETAIL_HALL_RELATED}
                    ></HallComponent>
                )}
                {filterHallUpSell && (
                    <HallComponent
                        filterGroups={filterHallUpSell}
                        horizontal={true}
                        skeletonQty={1}
                        useTitleInQuery={false}
                        title={NEXT_PUBLIC_PRODUCT_DETAIL_HALL_UP_SELL}
                    ></HallComponent>
                )}
                {filterHallCrossSell && (
                    <HallComponent
                        filterGroups={filterHallCrossSell}
                        horizontal={true}
                        skeletonQty={1}
                        useTitleInQuery={false}
                        title={NEXT_PUBLIC_PRODUCT_DETAIL_HALL_CROSS_SELL}
                    ></HallComponent>
                )}
            </>
        </div>
    );
}

export default Hall;
