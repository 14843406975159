import { AxiosRequestConfig } from 'axios';
import { ICookieCtx } from '../../utils/cookie';
import { NEXT_PUBLIC_STORE_DEFAULT_ELASTIC } from '../../utils/envs';
import { replaceUndefinedWithNull } from '../../utils/string';
import { apiNext } from '../api';
import establishmentService from '../establishment';
import { IProductExtensionAttributesLinks, ProductCustomAttributes } from '../product/types';
import { GetAll } from '../search';
import { IAggs, IBoolQuery, IElastic, IElasticItem, IQuery } from './types';
import ProductService from '../product';

export interface IGetProducts {
    filter: IBoolQuery;
    limit?: number;
    page?: number;
    sort?: SortFieldKeys | any;
    paramsAxios?: AxiosRequestConfig;
    aggs?: IAggs;
    elasticId?: string;
    cookiesParams?: ICookieCtx;
}

type SortFields = {
    [key: string]: { [key: string]: string } | string;
};

export const sortFields: SortFields = {
    relevance: '_score',
    position: 'position',
    price_asc: { original_price: 'asc' },
    price_desc: { original_price: 'desc' },
    name_asc: { 'name.keyword': 'asc' },
    name_desc: { 'name.keyword': 'desc' },
    discount: '_score'
};
export type SortFieldKeys = keyof typeof sortFields;

const getProducts = async ({
    filter,
    limit,
    page = 1,
    sort = 'relevance',
    paramsAxios,
    aggs,
    elasticId,
    cookiesParams
}: IGetProducts) => {
    let elasticIdParam = elasticId;
    if (!elasticIdParam) {
        const establishment = await establishmentService.getEstablishmentsStorage(cookiesParams);
        if (establishment && establishment.list?.length) {
            elasticIdParam = establishment.list?.[0]?.storeId?.toString();
        }
    }
    if (!elasticIdParam) {
        elasticIdParam = NEXT_PUBLIC_STORE_DEFAULT_ELASTIC;
    }

    // const apiElastic = axiosService.getApiElastic(undefined, elasticId);

    let newFilter = filter;
    const hasFIlterPrice = filter?.bool?.filter?.filter((v: any) => v.range?.original_price);
    if (!hasFIlterPrice?.length) {
        newFilter = {
            ...filter,
            ...{
                bool: {
                    ...filter.bool,
                    filter: [
                        ...(filter.bool?.filter || []),
                        {
                            range: {
                                original_price: { gt: 0 }
                            }
                        }
                    ]
                }
            }
        };
    }

    const getSort = () => {
        if (Array.isArray(sort)) {
            return sort;
        } else if (typeof sort === 'string') {
            return [sortFields[sort]];
        }
        return [sort];
    };

    const query: IQuery = {
        query: newFilter,
        size: limit,
        from: limit ? (page - 1) * limit : 0,
        aggs,
        // sort: typeof sort === 'string' ? ([sortFields[sort]] as unknown as Record<string, string>) : sort
        sort: [{ is_out_of_stock: { order: 'desc' } }, ...getSort()]
    };

    // const { data } = await apiElastic.post<IElastic>(``, query, paramsAxios);
    const { data } = await apiNext.post<IElastic>(`elastic?elasticId=${elasticIdParam}`, query, paramsAxios);

    return await formatList(data);
};

const getCategoryLinks = (product: IElasticItem['_source']): IProductExtensionAttributesLinks[] => {
    let categoryLinks: IProductExtensionAttributesLinks[] = [];
    if (product.category_ids) {
        product.category_ids.map((id: number) => {
            const key = `position_category_${id}`;
            if (key in product !== undefined) {
                categoryLinks.push({
                    position: parseInt(product[key]),
                    category_id: id
                });
            }
        });
    }

    return categoryLinks;
};

const getCustomAttributes = (product: IElasticItem['_source']): ProductCustomAttributes[] => {
    const customAttributes: ProductCustomAttributes[] = [];
    const keys = [
        'tipo_medida',
        'url_key',
        'meta_title',
        'meta_keyword',
        'meta_description',
        'special_from_date',
        'tipo_medida',
        'special_price',
        'description',
        'special_to_date'
    ];

    keys.map((key) => {
        if (key in product) {
            let value = Array.isArray(product[key]) ? product[key][0] : product[key];
            if (['special_from_date', 'special_to_date'].includes(key) && value) {
                value = value.replace('T', ' ').replace('+00:00', '');
            }

            customAttributes.push({
                attribute_code: key,
                value: value === undefined ? null : value
            });
        }
    });
    return customAttributes;
};

const getPriceProduct = (product: IElasticItem['_source']) => {
    const original_special_price = Number(product.original_special_price);
    const original_price = Number(product.original_price);

    if (original_special_price > 0) {
        const specialFromDate = product.special_from_date?.replace('T', ' ').replace('+00:00', '');
        const specialToDate = product.special_to_date?.replace('T', ' ').replace('+00:00', '');

        const hasDiscount = ProductService.getPercentDiscountElastic(
            original_special_price,
            original_price,
            specialFromDate,
            specialToDate
        );

        if (hasDiscount?.percentage) {
            return {
                price: original_special_price,
                special_price: original_price,
                discount_percentage: hasDiscount.percentage
            };
        }
    }

    return {
        price: original_price
    };
};

export const formatProductFromElastic = (id: string, product: IElasticItem['_source']): any => {
    const getValue = (v: any) => {
        if (v === undefined) return null;
        return Array.isArray(v) ? v[0] : v;
    };

    const parseJson = (v: any) => {
        try {
            return JSON.parse(v);
        } catch (e) {
            return null;
        }
    };

    const parseAttributes: any = {
        altura: 'Altura',
        largura: 'Largura',
        profundidade: 'Profundidade',
        pesobruto: 'Peso Bruto',
        codigobarras: 'Código de Barras',
        marcadescricao: 'Marca',
        B1_XINFLAM: 'Inflamável',
        EC_ABNT: 'Norma ABNT',
        EC_AMBRECOM: 'Ambientes Recomendados',
        EC_APLIC: 'Aplicação',
        EC_ARIND: 'Área Indicada',
        EC_ATEN: 'Atenção',
        EC_AVANCOR: 'Avanço do Corte',
        EC_BAT: 'Bateria',
        EC_BIT: 'Bitola',
        EC_CAPACI: 'Capacidade',
        EC_CAPCARG: 'Capacidade de Carga',
        EC_CAPERFALV: 'Capacidade de Perfuração em Alvenaria (mm)',
        EC_CAPERFMAD: 'Capacidade de Perfuração em Madeira (mm)',
        EC_CAPMETAL: 'Capacidade Metal',
        EC_CARGBAT: 'Capacidade da Bateria',
        EC_CONTEMB: 'Conteúdo da Embalagem',
        EC_COR: 'Cor',
        EC_CORLUZ: 'Cor da luz',
        EC_DIAMBROCA: 'Diâmetro da Broca',
        EC_DIAMCAN: 'Diâmetro do Caneco',
        EC_DIAMCM: 'Diâmetro em cm',
        EC_DIMENS: 'Dimensões do Produto',
        EC_DISPLAY: 'Possui Display Sim/Não?',
        EC_DISTFUROCAN: 'Distância do Furo do Caneco',
        EC_ESPEMM: 'Espessura (mm)',
        EC_EST: 'Estilo',
        EC_FERRMINTERC: 'Ferramenta Intercambiável',
        EC_FLUXAR: 'Fluxo de Ar litros/min',
        EC_FORCIMP: 'Força de Impacto',
        EC_FORM: 'Formato',
        EC_GARANT: 'Garantia',
        EC_GRAORES: 'Grão Resina',
        EC_INFADIC: 'Informações Adicionais',
        EC_INTER: 'Possui Interruptor',
        EC_LIN: 'Linha',
        EC_MAXCORT: 'Máximo Corte',
        EC_MEDABA: 'Medida da Aba',
        EC_MOD: 'Modelo',
        EC_MODOFIX: 'Modo de Fixação',
        EC_PARAF: 'Parafuso',
        EC_POT: 'Potência',
        EC_PRESSAO: 'Pressão (PSI)',
        EC_PROF45: 'Profundidade Corte 45º',
        EC_PROF90: 'Profundidade do Corte 90º',
        EC_PROFCAN: 'Profundidade do Caneco',
        EC_PROFMINFURO: 'Profundidade Mínima do Furo',
        EC_PROTSOBRCARGA: 'Com Proteção contra Sobrecarga',
        EC_QTOM: 'Quantidade de Tomadas',
        EC_QTSAIDA: 'Quantidade de Saídas',
        EC_REV: 'Reversível (Sim/Não)?',
        EC_ROTMAX: 'Rotação Máxima (Abrasivos)',
        EC_RPM: 'RPM',
        EC_SUPAPLI: 'Superfície da Aplicação',
        EC_TAMANHO: 'Tamanho',
        EC_TAMLIX: 'Tamanho da Lixa',
        EC_TECNSOFT: 'Tecnologia Soft Close',
        EC_TEMPCOR: 'Temperatura de Cor (k)',
        EC_TEMTRA: 'Temperatura Trabalho Cº',
        EC_TEMVAR: 'Temperatura variável (Sim/Não)?',
        EC_TENELET: 'Tensão Elétrica',
        EC_TIPDIS: 'Tipo do Disco',
        EC_TIPMOTO: 'Tipo de Motor',
        EC_TIPOENTRADA: 'Tipo de Entrada',
        EC_TIPOSAIDA: 'Tipo de Saída',
        EC_TORQMAX: 'Torque Máximo',
        EC_TPCAB: 'Tipo de Cabo',
        EC_TPENCAIX: 'Encaixe',
        EC_TPTOM: 'Tipo de Tomada de Energia',
        EC_USB: 'Possui USB?',
        EC_VAZAO: 'Vazão (Litros/Hora)',
        EC_VELOVARI: 'Velocidade Variável?',
        EC_VOLTSFI: 'Voltagem Ferramentas sem fio'
    };

    try {
        const attributes = Object.keys(product)
            .filter((key) => key.startsWith('attr_'))
            .reduce((acc: any, key) => {
                const originalKey = key.replace('attr_', '').replace('_value', '');
                const newKey = parseAttributes[originalKey] || originalKey;

                switch (originalKey) {
                    case 'altura':
                    case 'largura':
                    case 'profundidade':
                    case 'EC_DIAMCM':
                        acc[newKey] = `${product[key]} cm`;
                        break;
                    case 'pesobruto':
                        acc[newKey] = `${product[key]} Kg`;
                        break;
                    default:
                        acc[newKey] = product[key];
                }

                return acc;
            }, {});

        let installments = null;
        if (product?.installments) {
            installments = JSON.parse(product.installments).data;
        }

        return {
            id: parseInt(getValue(id)),
            sku: getValue(product.sku),
            name: getValue(product.name),
            ean: Number(getValue(product.ean) || 0).toString(),
            banner_sku: getValue(product?.banner_sku),
            status: parseInt(getValue(product.status)),
            visibility: parseInt(getValue(product.visibility)),
            weight: getValue(product.weight),
            extension_attributes: {
                unit_weight: getValue(product.weight),
                category_links: getCategoryLinks(product)
            },
            attributes: Object.keys(attributes).length ? attributes : {},
            product_links: parseJson(product.all_product_links),
            option: [],
            inStock: getValue(product.is_out_of_stock) == 1,
            configurable_options: product.configurable_options ? parseJson(product.configurable_options) : null,
            tier_prices: [],
            installments,
            custom_attributes: getCustomAttributes(product),
            var_kg: product.var_kg ? parseFloat(product.var_kg) : null,
            ...getPriceProduct(product)
        };
    } catch (e) {
        console.log('Error on formatProductFromElastic', e);
    }
};

const formatList = async (data: IElastic): Promise<GetAll> => {
    function checkUndefinedProperties(product: any): any {
        for (let key in product) {
            if (product[key] === undefined) {
                product[key] = null;
            } else if (typeof product[key] === 'object' && product[key] !== null) {
                product[key] = checkUndefinedProperties(product[key]);
            }
        }
        return product;
    }

    const products = data.hits.hits
        .map((product) => {
            let result: any = null;
            try {
                result = formatProductFromElastic(product._id, product._source);
                result = checkUndefinedProperties(result);
            } catch (e) {
                console.error('Error on formatProductFromElastic', e);
            }

            return result !== undefined ? result : null;
        })
        .filter((v) => v !== null);

    let formatted = {
        items: products,
        total_count: data.hits.total.value,
        aggregations: data.aggregations || null
    };

    return replaceUndefinedWithNull(formatted);
};

export default {
    getProducts
};
