import { FC, SVGProps } from 'react';

const IconCartOutline: FC<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.40902 14.246L5.87402 7H18.5C19.151 7 19.628 7.611 19.47 8.243L18.122 13.635C17.917 14.454 17.221 15.056 16.381 15.14L9.56502 15.822C8.54902 15.923 7.62002 15.244 7.40902 14.246Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M5.874 7L5.224 4H3.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M17.1088 19.2668C16.9068 19.2668 16.7428 19.4308 16.7448 19.6328C16.7448 19.8348 16.9088 19.9988 17.1108 19.9988C17.3128 19.9988 17.4768 19.8348 17.4768 19.6328C17.4758 19.4308 17.3118 19.2668 17.1088 19.2668"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.69724 19.2673C8.49524 19.2673 8.33124 19.4313 8.33324 19.6333C8.33124 19.8363 8.49624 20.0003 8.69824 20.0003C8.90024 20.0003 9.06424 19.8363 9.06424 19.6343C9.06424 19.4313 8.90024 19.2673 8.69724 19.2673"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default IconCartOutline;
