import Router from 'next/router';
import { ILink } from './types';
import * as NProgress from 'nprogress';

const toRoute = async ({ options: optionsParams, onClick }: ILink) => {
    NProgress.start();
    if (onClick) {
        const result = await onClick();
        if (result) return;
    }
    const { url, as, options } = optionsParams;
    if (optionsParams.replace) {
        Router.replace(url, as, { ...options, shallow: false });
    } else {
        Router.push(url, as, options);
    }
};

export default { toRoute };
