import tw, { styled } from 'twin.macro';
import { TextNunitoRegular16 } from '../../../atoms';
import IconMenu from '../../../atoms/icons/Menu';
import { motion } from 'framer-motion';

export const Container = styled(motion.div)`
    ${tw`
        md:px-[1%]
        hidden
        md:flex
        flex-col
        border-t-[#CECECE]
        border-t-[0.5px]
        border-b-[#CECECE]
        border-b-[0.5px]
    `}
`;

export const Content = styled(motion.ul)`
    ${tw`
        h-16
        flex
        flex-row
        items-center
        gap-6
    `}
`;

export const Menu = styled.li`
    ${tw`
        cursor-pointer
        gap-2
        flex
        flex-row
        items-center
    `}
`;

export const Icon = styled(IconMenu)`
    ${tw`
        w-6
        h-4
        fill-[#9FBE43]
    `}
`;

export const MenuText = styled.div`
    ${tw`
        text-16
        font-normal
    `}
`;

export const List = styled.div`
    ${tw`
        hidden 
        flex-row 
        items-center 
        gap-6
    `}
    @media (min-width: 1285px) {
        ${tw`flex`};
    }
`;
