import tw, { styled } from 'twin.macro';
import { IconPersonRounded } from '../../atoms';
import { Button } from '../../molecules';

interface FormSubmitButtonProps {
    width?: string;
    height?: string;
}

export const Container = styled.div`
    ${tw`
        grid
        grid-cols-[auto]
        grid-rows-[0px 20px 389px 114px 55px]
        
        lg:grid-rows-[100px 104px 131px 96px]
        lg:grid-cols-[6fr 1fr 1fr 6fr]
        `}
`;

export const TitleContainer = styled.div`
    ${tw`
        row-start-1
        flex
        flex-col
        items-center
        lg:items-start
        lg:pl-[40px]
    `}
`;

export const PersonIcon = styled(IconPersonRounded)`
    ${tw`
        fill-primary
        mb-[20px]

        lg:hidden
    `}
`;

export const TitleLabel = styled.div`
    ${tw`
        font-nunito
        font-[900]
        text-[25px]
    `}
`;

export const ThirdPartyLoginContainer = styled.div`
    ${tw`flex flex-col items-center col-start-1 row-start-2  lg:col-start-4 lg:row-start-3 lg:items-start lg:justify-center`}
`;

export const LoginFormContainer = styled.div`
    ${tw`flex flex-col col-start-1 row-start-3  lg:col-start-1 lg:row-span-3`}
`;

export const ContainerTitle = styled.span`
    ${tw`
    font-nunito
    font-[400]
    text-[16px]

    lg:text-[18px]
    `}
`;

export const ContainerDescription = styled.span`
    ${tw`
    font-nunito
    font-[400]
    text-[13px]
    text-[#000]
    mt-[10px]
    mb-[30px]
    flex

    lg:mb-[0px]
    lg:text-[15px]
    lg:items-center
    `}
`;

export const FormSubmitButton = styled(Button)<FormSubmitButtonProps>`
    ${tw`
    w-full
    rounded-[10px]
  `}
    ${({ width }) => width && `width: ${width};`}
    ${({ height }) => height && `height: ${height};`}
`;

export const DivisoryLine = styled.div`
    ${tw`
    hidden

    lg:col-start-3
    lg:row-start-2
    lg:row-end-4
    lg:flex
    lg:border-l-[1px]
    lg:border-l-gray
    `}
`;

export const RegisterFormContainer = styled.div`
    ${tw`flex flex-col col-start-1 row-start-4  lg:row-start-2 lg:row-end-4 lg:col-start-4`}
`;

export const RegisterFormButtonContainer = styled.div`
    ${tw`col-start-1 row-start-5  lg:row-start-3 lg:col-start-4`}
`;
