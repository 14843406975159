import NodeCache from 'node-cache';

const cache = new NodeCache();

const getCacheFirst = async (key: any, fn: any, time = 60 * 5) => {
    const exists = cache.get(key.toString());
    if (exists) {
        return exists;
    }

    const value = await fn();
    cache.set(key.toString(), value, time); //5 min

    return value;
};

export default {
    cache,
    getCacheFirst
};
