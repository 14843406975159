import { AnimatePresence } from 'framer-motion';
import { Ref, RefObject, forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useOutsideClick } from '../../../../hooks';
import { Portal } from '../../../atoms';
import { Close, Container, Description, Title } from './styles';
import { Button } from '../../../molecules';
import { IButtonVariant } from '../../../molecules/Button/types';

export interface IChangeMyRegionModalHandle {
    toggle: (x: number, y: number) => void;
}

export interface IChangeMyRegionModalProps {
    onClose?: () => void;
    onClickChangeZipCode: () => void;
    refOutSideClick: RefObject<HTMLDivElement> | RefObject<HTMLButtonElement>;
}

const ChangeMyRegionModal = forwardRef(function ChangeMyRegionModal(
    { onClose, refOutSideClick, onClickChangeZipCode }: IChangeMyRegionModalProps,
    ref: Ref<IChangeMyRegionModalHandle>
) {
    const [isOpen, setIsOpen] = useState<{ x: number; y: number } | undefined>(undefined);
    const refContainer = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => ({
        toggle: (x: number, y: number) =>
            setIsOpen((old) => {
                if (old) return undefined;

                return { x, y };
            })
    }));
    useOutsideClick([refContainer, refOutSideClick], () => {
        if (isOpen) setIsOpen(undefined);
        onClose?.();
    });

    const handleClickChangeRegion = () => {
        setIsOpen(undefined);
        onClickChangeZipCode();
    };

    return (
        <Portal>
            <AnimatePresence>
                {isOpen && (
                    <Container
                        style={{ left: isOpen.x, top: isOpen.y }}
                        ref={refContainer}
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                    >
                        <Title>Minha Região</Title>
                        <Description>
                            Para acessar produtos e ofertas da sua região, identifique onde você está?
                        </Description>
                        <Button
                            text="Inserir CEP"
                            variant={IButtonVariant.PRIMARY_MEDIUM}
                            onClick={handleClickChangeRegion}
                        ></Button>
                        <Close
                            onClick={() => {
                                setIsOpen(undefined);
                                onClose?.();
                            }}
                        ></Close>
                    </Container>
                )}
            </AnimatePresence>
        </Portal>
    );
});

export default ChangeMyRegionModal;
