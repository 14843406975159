import { DefaultSeo } from 'next-seo';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Loading } from '.';
import { useCachedResources, useStates, useVersion } from '../../hooks';
import useReactGa4 from '../../hooks/use-react-ga4';
import {
    NEXT_PUBLIC_GOOGLE_GTM,
    NEXT_PUBLIC_GOOGLE_SITE_VERIFICATION,
    NEXT_PUBLIC_ROBOTS,
    NEXT_PUBLIC_TITLE,
    NEXT_PUBLIC_URL_API_NEXT
} from '../../utils/envs';
import { hasHash } from '../../utils/string';
import ModalPortal from '../atoms/Modal-portal';
import ModalCheckout from './Modal-checkout';

const Cached = ({ Component, pageProps = {} }: any) => {
    const [showModalCheckout, setShowModalCheckout] = useState(false);
    const { isLoading } = useCachedResources();
    useStates();
    useVersion();
    useReactGa4();

    const getContent = () => {
        if (isLoading) return <Loading />;

        return <Component {...{ ...pageProps }} />;
    };
    useEffect(() => {
        if (hasHash('checkout')) {
            setShowModalCheckout(true);
        }
    }, []);

    const handleCloseModalCheckout = useCallback(() => {
        setShowModalCheckout(false);
    }, []);

    const router = useRouter();

    useEffect(() => {
        const handleRouteChange = () => {
            const modals = document.querySelectorAll('.modal');
            if (modals.length) return;

            const html = document.getElementsByTagName('body')[0];
            if (!html) return;
            html.style.overflow = '';
            html.style.paddingRight = '';
        };

        router.events.on('routeChangeStart', handleRouteChange);

        return () => {
            router.events.off('routeChangeStart', handleRouteChange);
        };
    }, [router.events]);

    return (
        <>
            <Head>
                <meta
                    name="viewport"
                    content={`width=device-width, initial-scale=1.0, minimum-scale=0.5, maximum-scale=1.0, user-scalable=no`}
                />

                <script
                    id="google-analytics"
                    dangerouslySetInnerHTML={{
                        __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({\'gtm.start\':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','${NEXT_PUBLIC_GOOGLE_GTM}');`
                    }}
                />
                <script src="https://s3.amazonaws.com/raichu-beta/ra-verified/bundle.js" />
                <meta name="adopt-website-id" content="291dab8c-5547-4ed0-a5a7-91e106a7a2f1" />

                {/* {establishment?.googleGtagToken && (
                    <>
                        <Script
                            src={`https://www.googletagmanager.com/gtag/js?id=${establishment?.googleGtagToken}`}
                            strategy="afterInteractive"
                        />
                        <Script id="gtag" strategy="afterInteractive">
                            {`
                                window.dataLayer = window.dataLayer || [];
                                function gtag(){window.dataLayer.push(arguments);}
                                gtag('js', new Date());
                                gtag('config', '${establishment?.googleGtagToken}');
                            `}
                        </Script>
                    </>
                )}
                {establishment?.metaPixelToken && (
                    <script
                        id="meta-pixel"
                        dangerouslySetInnerHTML={{
                            __html: `!function(f,b,e,v,n,t,s)
                            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                            n.queue=[];t=b.createElement(e);t.async=!0;
                            t.src=v;s=b.getElementsByTagName(e)[0];
                            s.parentNode.insertBefore(t,s)}(window, document,'script',
                            'https://connect.facebook.net/en_US/fbevents.js');
                            fbq('init', '${establishment?.metaPixelToken}');
                            fbq('track', 'PageView');`
                        }}
                    />
                )}

                {establishment?.googleGTMToken && (
                    <script
                        dangerouslySetInnerHTML={{
                            __html: `(function (a, b, c, d, e, f, g) {
                        a['CsdpObject'] = e; a[e] = a[e] || function () {
                        (a[e].q = a[e].q || []).push(arguments)
                        }, a[e].l = 1 * new Date(); f = b.createElement(c),
                        g = b.getElementsByTagName(c)[0]; f.async = 1; f.src = d; g.parentNode.insertBefore(f, g)
                        })(window, document, 'script', '//device.clearsale.com.br/p/fp.js', 'csdp');
                        csdp('app', 'smentrega');
                        csdp('sessionid', '${sessionId}');`
                        }}
                    />
                )}  */}
                <meta name="google-site-verification" content={NEXT_PUBLIC_GOOGLE_SITE_VERIFICATION} />
                <link rel="icon" href="/favicon.ico" type="image/x-icon" />
                <meta name="robots" content={NEXT_PUBLIC_ROBOTS} />
            </Head>

            <noscript
                dangerouslySetInnerHTML={{
                    __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${NEXT_PUBLIC_GOOGLE_GTM}" height="0" width="0" style="display: none; visibility: hidden;" />`
                }}
            />

            <DefaultSeo
                title={NEXT_PUBLIC_TITLE}
                norobots={true}
                description="O Matcon.Casa é o marketplace especializado para materiais de construção, ferramentas e decoração. Entrega rápida e qualidade garantida."
                openGraph={{
                    images: [
                        {
                            url: `${NEXT_PUBLIC_URL_API_NEXT}favicon.ico`,
                            width: 32,
                            height: 32,
                            alt: 'Favicon'
                        }
                    ]
                }}
            />

            <ToastContainer position="top-center" />
            {getContent()}
            <div id="portal-root"></div>
            <ModalPortal>{showModalCheckout && <ModalCheckout onClose={handleCloseModalCheckout} />}</ModalPortal>
        </>
    );
};

export default Cached;
