import React, { FC, PropsWithChildren } from 'react';
import { Container, Content } from './styles';
import { HTMLMotionProps, MotionProps } from 'framer-motion';

interface ILayoutFitterProps extends HTMLMotionProps<'div'> {
    disabledOverflow?: boolean;
    withoutPaddingMobile?: boolean;
}

const LayoutFitter = ({
    children,
    disabledOverflow = false,
    withoutPaddingMobile = false,
    ...props
}: PropsWithChildren<ILayoutFitterProps>) => {
    return (
        <Container $withoutPaddingMobile={withoutPaddingMobile} {...props}>
            <Content disabledOverflow={disabledOverflow}>{children}</Content>
        </Container>
    );
};

export default LayoutFitter;
