import { FC, HTMLAttributes } from 'react';
import { Footer, Header } from '../../organisms';
import { Container } from './styles';
interface ILayoutProps extends Partial<HTMLAttributes<HTMLDivElement>> {}

const Layout: FC<ILayoutProps> = ({ children }) => {
    return (
        <Container>
            <Header />
            <main>{children}</main>
            <Footer />
        </Container>
    );
};

export default Layout;
