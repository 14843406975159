/** @type {import('tailwindcss').Config} */
module.exports = {
    mode: 'jit',
    content: ['./src/**/*.{js,jsx,ts,tsx}'],
    theme: {
        colors: {
            primary: '#9FBE43',
            primaryMedium: process.env.NEXT_PUBLIC_COLOR_PRIMARY_MEDIUM || '',
            primaryDark: process.env.NEXT_PUBLIC_COLOR_PRIMARY_DARK || '',
            gray: '#C8C8C8',
            lightGray: '#EDEDED',
            darkGray: process.env.NEXT_PUBLIC_COLOR_DARK_GRAY || '',
            black: '#000000',
            white: '#FFFFFF',
            green: process.env.NEXT_PUBLIC_COLOR_GREEN || '',
            transparent: 'transparent'
        },
        backgroundImage: {
            searchZipcode: "url('/images/bg.jpeg')"
        },
        extend: {
            width: {
                modal: '80%',
                modalSm: '100%'
            },
            height: {
                modalSm: '95%',
                modal: '90%'
            },
            maxHeight: {
                modal: '800px',
                modalSm: '95%'
            },
            maxWidth: {
                modalForceSm: '414px',
                modalSm: '100%',
                modal: '1280px'
            },
            fontSize: {
                20: '20px',
                10: ['10px', '13.64px'],
                12: ['12px', '16.37px'],
                13: '13px',
                14: ['14px', '19.1px'],
                15: '15px',
                16: ['16px', '21.82px'],
                18: ['18px', '24.55px'],
                22: ['22px', '30.01px'],
                26: ['26px', '35px'],
                25: '25px',
                32: ['32px', '44px']
            },
            borderRadius: {
                5: '5px',
                8: '8px',
                10: '10px',
                12: '12px',
                15: '15px',
                16: '16px',
                20: '20px',
                28: '28px',
                30: '30px'
            },
            boxShadow: {
                md: '0px 4px 10px rgba(0, 0, 0, 0.2)'
            },
            fontFamily: {
                nunito: ['Nunito'],
                lato: ['Nunito'],
                redHatDisplay: ['Red Hat Display'],
                latoHeavy: ['lato-heavy']
            },
            screens: {
                sh: { raw: '(max-height: 705px)' }
            }
        }
    },
    plugins: [require('@tailwindcss/line-clamp')]
};
