import { useEstimateShippingProduct } from '../../../../../hooks/use-estimate-shipping-product';
import { ProductService } from '../../../../../services';
import { IProduct } from '../../../../../services/product/types';
import { currencyBrl } from '../../../../../utils/getFormattedValue';
import { useQtyProduct } from '../../hooks/useQtyProduct';
import Buttons from '../Buttons';
import { Container, Installments, Price, Row, Seller, Shipping } from './styles';

interface AddCartMobileProps {
    data: IProduct;
}

function AddCartMobile({ data }: AddCartMobileProps) {
    const { qty } = useQtyProduct();

    const { data: estimateShipping } = useEstimateShippingProduct(data?.sku, qty, {
        refetchOnMount: false
    });

    const bestPrice = estimateShipping?.sort((a, b) => a.price_incl_tax - b.price_incl_tax)?.[0];

    return (
        <Container>
            <Row>
                <Seller>
                    Vendido e entregue por <strong>MATCON.CASA</strong>
                </Seller>
                <div>
                    {data.special_price && (
                        <Installments className="line-through">de R$ {currencyBrl(data.special_price)}</Installments>
                    )}
                    <Price>R$ {currencyBrl(ProductService.getValue(data, 1))}</Price>
                    <Installments></Installments>
                    {/* <Installments>em até 12x sem Juros</Installments> */}
                </div>
            </Row>
            {bestPrice && (
                <Row className="mt-[3px] items-center">
                    <Shipping>Frete a partir de</Shipping>
                    <Shipping className="text-right">R$ {currencyBrl(bestPrice.price_incl_tax)}</Shipping>
                </Row>
            )}
            <div>
                <Buttons className="!flex-row-reverse !pt-3" data={data} />
            </div>
        </Container>
    );
}

export default AddCartMobile;
