import { QueryObserver, QueryObserverOptions } from '@tanstack/react-query';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import EventEmitter from 'events';
import { toast } from 'react-toastify';
import { getCookie } from '../../utils/cookie';
import ReactGa4Service from '../react-ga4';

import { AddressService, UserService } from '..';
import { NEXT_PUBLIC_MSG_CART_ADD, NEXT_PUBLIC_MSG_CART_REMOVE } from '../../utils/envs';
import getFormattedValue from '../../utils/getFormattedValue';
import { CART, CART_ITEMS, HALL_LAST_ORDER, ORDERS } from '../../utils/react-query-keys';
import { replaceMsgParams } from '../../utils/string';
import { Address } from '../address/types';
import api from '../api';
import authService from '../auth';
import axiosService from '../axios';
import { ICartIdStorageParams, IGetInfoParams, IInitCartParams, IListParams, IUpdateCart } from '../card/types';
import {
    BuyAgainParams,
    Cart,
    CartItem,
    CartItemProductOption,
    CartItemWithElastic,
    CartShippingMethod,
    CheckoutParams,
    CreateTimeOut,
    EMeasureType,
    IBlockPurchaseCEP,
    IBlockPurchaseCEPRes,
    IBuyAgain,
    ICreditCardCheckout,
    IRefetchCartParams,
    IToastMessage,
    InfoCart,
    PixPaymentRes,
    ResponseTimeOut,
    SetAddressParams,
    ShippingInformationResponse,
    SubscribeFn,
    UpdateItemParams
} from '../cart/types';
import CookieService from '../cookie';
import { formatProductFromElastic } from '../elastic';
import { EntityId } from '../order/types';
import paymentService from '../payment';
import { IAllPaymentMethods, PaymentInterface, PaymentTypes } from '../payment/types';
import { queryClient } from '../query-client';

export const cartEvent = new EventEmitter();

const timeoutItems: { [key: string]: ResponseTimeOut } = {};

export class PaymentMethodOrder {
    private static _finalOrderId: number | null = null;
    private static _paymentMethod: string | null = null;

    static setPaymentMethodId(orderId: number | null, code: string | null) {
        this._finalOrderId = orderId;
        this._paymentMethod = code;
    }

    static getPaymentMethodId(): { orderId: number | null; code: string | null } | null {
        return {
            orderId: this._finalOrderId,
            code: this._paymentMethod
        };
    }
}

const initCart = async ({ forceNewId = false, req, res }: IInitCartParams) => {
    const [token, cartId] = await Promise.all([
        authService.getUserTokenStorage({ req, res }),
        getCartIdStorage({ req, res })
    ]);

    let response;
    if (token) {
        response = await axiosService.getClientApi({ req, res }).post('V1/carts/mine');
    } else {
        if (cartId && !forceNewId) {
            return cartId;
        }
        response = await axiosService.getClientApi({ req, res }).post('V1/guest-carts');
    }
    if (response) {
        setCartIdStorage(response.data.toString(), { req, res });

        return response.data.toString();
    }
};
const initCartLogged = async (params?: AxiosRequestConfig) => {
    return api.post('V1/carts/mine', null, params);
};

const getInfo = async (params?: AxiosRequestConfig, rest?: IGetInfoParams): Promise<Partial<InfoCart> | null> => {
    const req = { req: rest?.req, res: rest?.res };
    const token = authService.getUserTokenStorage(req);
    const cartId = getCartIdStorage(req);

    const url = token ? 'V1/carts/mine/totals' : `V1/guest-carts/${cartId}/totals/`;
    const api = axiosService.getClientApi(req);

    const { data } = await api.get(url, {
        params: {
            fields: 'subtotal,discount_amount,shipping_amount,items_qty,grand_total,total_segments[code,value,title],coupon_code,base_currency_code'
        },
        ...params
    });

    return data;
};

const addItem = async (
    sku: string,
    qtd: number,
    options: CartItemProductOption | null = null
): Promise<CartItem | null> => {
    try {
        const { id: cartId } = queryClient.getQueryData<Cart>([CART]) as Cart;
        const token = authService.getUserTokenStorage();
        const endpoint = token ? 'V1/carts/mine/items' : `V1/guest-carts/${cartId}/items`;
        const post = {
            cartItem: {
                sku: sku,
                qty: qtd,
                quote_id: cartId,
                product_option: options
            }
        };

        const { data } = await api.post(endpoint, post);
        return data;
    } catch (e: any) {
        if (e?.response?.status == 404) {
            await refetchCart({ updateId: true });
            return addItem(sku, qtd, options);
        }

        throw e;
    }
};
const transferItemsCartLogged = async (
    cartIdGuest: number,
    customerId: number,
    storeId: number,
    params?: AxiosRequestConfig
) => {
    const { data } = await api.put(
        `V1/guest-carts/${cartIdGuest}`,
        {
            customerId,
            storeId
        },
        params
    );
    return data;
};
const estimateShippingMethods = async (
    address?: Address | null,
    axiosConfig?: AxiosRequestConfig
): Promise<CartShippingMethod[]> => {
    if (!address) return [];
    const { data } = await api.post(
        `V1/carts/mine/estimate-shipping-methods`,
        {
            address: {
                region_id: address.region.region_id,
                region_code: address.region.region_code,
                country_id: 'BR',
                postcode: address.postcode
            }
        },
        axiosConfig
    );
    return data;
};

const guestEstimateShippingMethods = async (
    address: Address,
    cartId: string,
    axiosConfig?: AxiosRequestConfig
): Promise<CartShippingMethod[]> => {
    const { data } = await api.post(
        `V1/guest-carts/${cartId}/estimate-shipping-methods`,
        {
            address: {
                region_id: address.region.region_id,
                region_code: address.region.region_code,
                country_id: 'BR',
                postcode: address.postcode
            }
        },
        axiosConfig
    );
    return data;
};

const removeCoupon = async () => {
    const token = authService.getUserTokenStorage();
    const cartId = getCartIdStorage();

    const url = token ? 'V1/carts/mine/coupons/' : `V1/guest-carts/${cartId}/coupons/`;

    const { data } = await api.delete(url);

    return data;
};

const addCoupon = async (coupon: string, params?: AxiosRequestConfig) => {
    const token = authService.getUserTokenStorage();
    const cartId = getCartIdStorage();

    const url = token ? 'V1/carts/mine/coupons/' : `V1/guest-carts/${cartId}/coupons/`;
    const { data } = await api.put(url + coupon, undefined, params);

    return data;
};

const updateQtdItem = async (
    itemId: number,
    qty: number,
    options?: CartItemProductOption,
    params?: AxiosRequestConfig
): Promise<CartItem | null> => {
    const [token, cartId] = await Promise.all([authService.getUserTokenStorage(), getCartIdStorage()]);

    const endpoint = token ? `V1/carts/mine/items/${itemId}` : `V1/guest-carts/${cartId}/items/${itemId}`;

    const post = {
        cartItem: {
            qty: qty,
            quote_id: cartId,
            product_option: options
        }
    };

    const { data } = await api.put(endpoint, post, params);

    return data;
};
const removeItem = async (itemId: number): Promise<CartItem | null> => {
    const [token, cartId] = await Promise.all([authService.getUserTokenStorage(), getCartIdStorage()]);
    const endpoint = token ? `V1/carts/mine/items/${itemId}` : `V1/guest-carts/${cartId}/items/${itemId}`;

    const { data } = await api.delete(endpoint);
    return data;
};

const getCartItems = async (): Promise<CartItem[] | null> => {
    const [token, cartId] = await Promise.all([authService.getUserTokenStorage(), getCartIdStorage()]);
    const endpoint = token ? `V1/carts/mine/items` : `V1/guest-carts/${cartId}/items`;

    const { data } = await api.get(endpoint);
    return data;
};

const list = async (params?: AxiosRequestConfig, rest?: IListParams): Promise<CartItemWithElastic[]> => {
    const res = { req: rest?.req, res: rest?.res };
    const api = axiosService.getClientApi(res);

    const cartId = await getCartIdStorage(rest);
    const response = await api.get(`V1/sellers/cart/items/${cartId}`, params);

    return response.data.map((item: any) => ({
        ...item,
        available: item?.available === undefined || item.available === '1',
        // available: parseFloat(item.qty) == 1 ? false : true,
        qty: parseFloat(item.qty),
        quantity: parseFloat(item.quantity),
        elastic: formatProductFromElastic(item.product_id, item.elastic)
    }));
};

const getProductsUnavailableCart = () => {
    const cart = queryClient.getQueryData([CART]) as Cart;

    if (!cart || !cart?.items) return [];

    return cart.items?.filter((item) => typeof item?.available !== 'undefined' && item.available === false);
};

const setCartIdStorage = (id: string | null, params?: ICartIdStorageParams) => {
    return CookieService.setCartIdStorage(id, params);
};
const getCartIdStorage = (params?: ICartIdStorageParams): string | null => {
    return CookieService.getCartIdStorage(params);
};

const getBillingAddress = async (address: Address) => {
    return {
        customerAddressId: address.id,
        countryId: 'BR',
        regionId: address.region_id,
        regionCode: address.region.region_code,
        region: address.region.region,
        customerId: address.customer_id,
        street: address.street,
        telephone: address.telephone,
        fax: null,
        postcode: address.postcode,
        city: address.city,
        firstname: address.firstname,
        lastname: address.lastname,
        middlename: null,
        prefix: address.prefix,
        suffix: address.suffix,
        vatId: address.vat_id,
        customAttributes: [],
        saveInAddressBook: null
    };
};

const setAddress = async ({ address, shippingMethod }: SetAddressParams) => {
    const billingAddress = getBillingAddress(address);
    const bodyShipping = {
        addressInformation: {
            shipping_address: billingAddress,
            billing_address: billingAddress,
            shipping_method_code: shippingMethod?.method_code,
            shipping_carrier_code: shippingMethod?.carrier_code,
            extension_attributes: {}
        }
    };
    const result = await shippingInformation(bodyShipping);
    queryClient.setQueryData(
        [CART],
        (old: Cart | undefined) =>
            ({
                ...old,
                info: result.totals
            }) as Cart
    );
    return result;
};
const shippingInformation = async (body: any): Promise<ShippingInformationResponse> => {
    const { data } = await api.post('V1/carts/mine/shipping-information', body);

    if (data.payment_methods) {
        //remove duplicate payment methods where code is the same
        data.payment_methods = data.payment_methods.filter((item: any, index: number) => {
            return data.payment_methods.findIndex((i: any) => i.code === item.code) === index;
        });
    }
    return data;
};

const getBlockPurchaseCEP = async (postcode: string): Promise<IBlockPurchaseCEPRes[] | []> => {
    const info: IBlockPurchaseCEP = {
        data: {
            cep: postcode
        }
    };
    const { data } = await api.post('V1/matcon/buscar-faixa-cep', info);

    return data;
};

const setShippingInformation = async (
    shippingMethod: CartShippingMethod,
    address: Address
): Promise<ShippingInformationResponse> => {
    const user = await UserService.getInfo();
    const body = {
        addressInformation: {
            shipping_address: {
                country_id: 'BR',
                region_id: address.region_id,
                region_code: address.region.region_code,
                region: address.region.region,
                customer_id: address.customer_id,
                street: address.street,
                telephone: address.telephone,
                postcode: address.postcode,
                city: address.city,
                firstname: address.firstname,
                lastname: address.lastname,
                email: user?.email,
                same_as_billing: 0,
                save_in_address_book: 0,
                customer_address_id: address.id,
                vat_id: address.vat_id ?? user?.taxvat
            },
            billing_address: {
                country_id: 'BR',
                region_id: address.region_id,
                region_code: address.region.region_code,
                region: address.region.region,
                customer_id: address.customer_id,
                street: address.street,
                telephone: address.telephone,
                postcode: address.postcode,
                city: address.city,
                firstname: address.firstname,
                lastname: address.lastname,
                email: user?.email,
                same_as_billing: 0,
                save_in_address_book: 0,
                customer_address_id: address.id,
                vat_id: address.vat_id ?? user?.taxvat
            },
            shipping_method_code: shippingMethod.method_code,
            shipping_carrier_code: shippingMethod.carrier_code
        }
    };

    const { data } = await api.post('V1/carts/mine/shipping-information', body);

    return data;
};

const creditCardCheckout = async (params: ICreditCardCheckout): Promise<IAllPaymentMethods | undefined> => {
    const userAddress = await AddressService.initAddress();
    const cartId = getCartIdStorage();
    const token = await getCreditCardToken(params);

    const validity = params.paymentValues.valid.split('/');

    if (userAddress && userAddress.address) {
        const body = {
            paymentMethod: {
                method: params.code,
                additional_data: {
                    cc_type: token.card.brand,
                    cc_last_4: params.paymentValues.number.slice(-4),
                    cc_exp_year: `20${validity[1]}`,
                    cc_exp_month: validity[0],
                    cc_owner: params.paymentValues.name,
                    cc_savecard: 1,
                    cc_saved_card: null,
                    cc_installments: params.paymentValues.installments.id,
                    cc_token_credit_card: token.id,
                    cc_card_tax_amount: 0,
                    cc_buyer_checkbox: false
                }
            }
        };

        const { data } = await api.put('V1/carts/mine/order', body);
        return data;
    }
};

const getCreditCardToken = async (value: ICreditCardCheckout): Promise<any> => {
    const validity = value.paymentValues.valid.split('/');
    /*const tokenBody = {
        card: {
            cc_type: value.paymentValues.type,
            cc_exp_year: validity[1],
            cc_exp_month: validity[0],
            cc_number: value.paymentValues.number,
            cc_owner: value.paymentValues.name,
            cvv: value.paymentValues.cvv
        }
    };*/

    const tokenBody = {
        card: {
            number: value.paymentValues.number,
            holder_name: value.paymentValues.name,
            holder_document: value.paymentValues.cpf,
            exp_month: validity[0],
            exp_year: validity[1],
            cvv: value.paymentValues.cvv,
            brand: value.paymentValues.brand,
            label: value.paymentValues.brand
        },
        type: 'card'
    };

    //const { data } = await api.post('/V1/create-card', tokenBody);
    const { data } = await axios.post('https://api.pagar.me/core/v5/tokens?appId=pk_P3WyVWDHEfm7Je4n', tokenBody);
    return data;
};

const checkoutSubmit = async (code: PaymentTypes): Promise<IAllPaymentMethods | undefined> => {
    const cartId = getCartIdStorage();
    /*const sessionId = getCookie('@delivei:session_id');

    const paymentClass = paymentService.getPaymentClass(params.paymentMethod) as PaymentInterface;

    const additionalData = (await paymentClass.getCheckout(params.card)).additional_data;*/

    const userAddress = await AddressService.initAddress();

    if (userAddress && userAddress.address) {
        const body = {
            cart_id: cartId,
            billingAddress: {
                customerAddressId: userAddress.address.id,
                countryId: 'BR',
                regionId: userAddress.address.region_id,
                regionCode: userAddress.address.region.region_code,
                region: userAddress.address.region.region,
                customerId: userAddress.address.customer_id,
                street: userAddress.address.street,
                telephone: userAddress.address.telephone,
                postcode: userAddress.address.postcode,
                city: userAddress.address.city,
                firstname: userAddress.address.firstname,
                lastname: userAddress.address.lastname,
                prefix: userAddress.address.prefix,
                vatId: userAddress.address.vat_id
            },
            paymentMethod: {
                method: code
            }
        };

        const { data } = await api.post('V1/carts/mine/payment-information', body);

        if (data) {
            const order = await placeOrder(code);
            return order.data;
        }
    }
};

export const placeOrder = async (code: PaymentTypes) => {
    const user = await UserService.getInfo();

    const body = {
        paymentMethod: {
            method: code
        },
        email: user?.email
    };

    const { data } = await api.put('V1/carts/mine/order', body);

    return data;
};

export const getPixPaymentInfo = async (orderId: number): Promise<PixPaymentRes> => {
    const { data } = await api.get(`V1/orders/${orderId}/link_payment`);

    const object = JSON.parse(data);

    return object;
};

export const getBilletPaymentInfo = async (orderId: number): Promise<string> => {
    const { data } = await api.get(`V1/orders/${orderId}/link_payment`);
    return data;
};

const checkout = async (params: CheckoutParams): Promise<EntityId> => {
    const cartId = await getCartIdStorage();
    const sessionId = getCookie('@delivei:session_id');

    const paymentClass = paymentService.getPaymentClass(params.paymentMethod) as PaymentInterface;

    const additionalData = (await paymentClass.getCheckout(params.card)).additional_data;

    const body = {
        cart_id: cartId,
        billingAddress: {
            customerAddressId: params.address.id,
            countryId: 'BR',
            regionId: params.address.region_id,
            regionCode: params.address.region.region_code,
            region: params.address.region.region,
            customerId: params.address.customer_id,
            street: params.address.street,
            telephone: params.address.telephone,
            fax: null,
            postcode: params.address.postcode,
            city: params.address.city,
            firstname: params.address.firstname,
            lastname: params.address.lastname,
            middlename: null,
            prefix: params.address.prefix,
            suffix: params.address.suffix,
            vatId: params.address.vat_id,
            customAttributes: [],
            saveInAddressBook: null
        },

        paymentMethod: {
            method: (await paymentClass.getCheckout(params.card)).method,
            additional_data: {
                ...additionalData,
                card_hash: params.card.find((item) => item.card_hash)?.card_hash,
                payment_token: sessionId,
                device: window?.navigator?.userAgent,
                origin: window?.location?.host?.includes('whatsapp') || 'desktop',
                affiliate_code: getCookie('@delivei:affiliate_code')
            }
        }
    };

    const { data } = await api.post<EntityId>('V1/carts/mine/payment-information', body);
    // await paymentService.setLastMethod({
    //     paymentMethod: params.paymentMethod,
    //     values: params.card.map((card) => ({
    //         ...card,
    //         installments: { quantity: 1, totalWithTax: 1, installmentTotal: 1, label: 'string' }
    //     }))
    // });

    ReactGa4Service.onPurchase(data);
    await refetchCart({ updateId: true, forceNewId: true });
    queryClient.invalidateQueries([CART]);
    queryClient.invalidateQueries([ORDERS]);
    queryClient.invalidateQueries([HALL_LAST_ORDER]);

    return data;
};

const buyAgain = async (products: IBuyAgain[] | undefined) => {
    const cartId = await getCartIdStorage();
    if (!products || (products && 'items' in products)) return [];
    const itemsBuyAgain: BuyAgainParams[] = products
        ?.filter(({ product }) => product.inStock)
        .map(({ product, qty }) => ({
            sku: product.sku,
            qty,
            name: product.name
        }));
    itemsBuyAgain.map(async (product, index) => {
        let promise = null;
        setTimeout(async () => {
            const post = {
                cartItem: {
                    sku: product.sku,
                    qty: product.qty,
                    quote_id: cartId,
                    product_option: product.options
                }
            };
            try {
                const { data } = await api.post('V1/carts/mine/items', post);
            } catch (error) {
                toast.error(`O produto ${product.name} não tem a quantidade ${product.qty} no estoque no momento!`);
            }
        }, 1000 * index);
        return promise;
    });
    toast.success('Os produtos estão sendo adicionados ao carrinho.', { autoClose: 1000 * (products.length + 1) });
    toast.success('Produtos adicionados!', { delay: 1000 * products.length, autoClose: 1000 });
    setTimeout(
        async () => {
            await refetchCart();
        },
        1000 * (products.length + 1)
    );
};

const findProduct = (
    items: CartItem[],
    optionProductSelected: CartItemProductOption | undefined,
    sku: string
): CartItem | undefined => {
    return items?.find((item) => findProductMethod(item, optionProductSelected, sku));
};

const findProductMethod = (item: CartItem, optionProductSelected?: CartItemProductOption, sku?: string) =>
    (optionProductSelected &&
        item.product_option &&
        item.sku == sku &&
        optionProductSelected.extension_attributes.configurable_item_options[0].option_value ==
            item.product_option.extension_attributes.configurable_item_options[0].option_value) ||
    (!optionProductSelected &&
        item.product_option &&
        item.sku == sku &&
        (item.product_option.extension_attributes.configurable_item_options[0].option_value as any) == '') ||
    (!optionProductSelected && !item.product_option && item.sku == sku);

const getTotalItems = (items: CartItem[]) => {
    return items.length ? items.map((item) => item.qty).reduce((a, b) => a + b, 0) : 0;
};
const getTotalPriceItems = (items: CartItem[]) => {
    return items.length ? items.map((product) => product.price * product.qty).reduce((a, b) => a + b) : 0;
};

const add = async (
    sku: string,
    qty: number,
    options?: CartItemProductOption,
    pageOrigin?: string
): Promise<void | AxiosError> => {
    if (getCartIdStorage() == null) {
        await updateCart({ updateId: true });
    }

    cartEvent.emit('onAddItems', { sku, qty });

    const result = await addItem(sku, qty, options);
    const newItems = await list();

    const itemDatalayer = {
        isAdd: true,
        product: {
            item_id: result?.sku,
            item_name: result?.name,
            price: result?.price,
            quantity: qty
        }
    };

    result && ReactGa4Service.onCartAddOrRemove(itemDatalayer);

    if (result) {
        const toastId = toastSuccess({
            isAdd: true,
            product: result
        });

        timeoutItems[result.item_id] = { isAdd: true, toastId };

        queryClient.setQueryData<Cart | undefined>([CART], (old: Cart | undefined) => {
            // const newItems = [...(old ? old.items : []), result as CartItem];

            return {
                ...old,
                info: {
                    ...old?.info,
                    subtotal: getTotalPriceItems(newItems)
                },
                totalItems: getTotalItems(newItems),
                items: newItems
            } as Cart;
        });
    }

    queryClient.resetQueries(['estimateShippingMethods']);
};

const createTimeOut: CreateTimeOut = (fn, ms, toastId, isAdd): ResponseTimeOut => {
    return {
        timeout: setTimeout(fn, ms),
        fn,
        toastId,
        isAdd
    };
};
const executeAllRequest = async (refetch = false) => {
    const result = await Promise.all(
        Object.keys(timeoutItems).map(async (key) => {
            const { fn, timeout } = timeoutItems[key];

            clearTimeout(timeout);
            if (fn) {
                return await fn();
            }
        })
    );

    if (refetch) {
        await refetchCart();
    }

    return result;
};
const hasExecuteRequest = () => Boolean(Object.keys(timeoutItems).length);
const updatePriceItem = (itemId: number, price: number) => {
    queryClient.setQueryData<Cart>([CART], (old) => {
        const newold = old as Cart;
        const exists = old?.items.find((item) => item.item_id == itemId);
        if (exists) {
            exists.price = price;
        }
        return {
            ...newold
        };
    });
};

const toastSuccess = ({ isAdd, isAddTimeout, product, toastId }: IToastMessage) => {
    const type = isAdd ? 'adicionou' : 'removeu';
    const msgToast = replaceMsgParams(isAdd ? NEXT_PUBLIC_MSG_CART_ADD : NEXT_PUBLIC_MSG_CART_REMOVE, {
        type: type,
        'product.name': product.name
    });

    const isActive = toastId != undefined && toast.isActive(toastId);

    if (toastId != undefined && isActive) {
        if (isAddTimeout !== undefined && isAddTimeout != isAdd) {
            toast.update(toastId, { render: msgToast });
        } else {
            toast.update(toastId);
        }
    } else {
        toastId = toast.success(msgToast, { autoClose: 1000 });
    }

    return toastId;
};

const updateItem = async ({ itemId, qty, option, pageOrigin, price, isAdd, name, wait }: UpdateItemParams) => {
    cartEvent.emit('onAddItems', { itemId, qty });

    const cart = queryClient.getQueryData([CART]) as Cart;
    const product = cart.items.find((item) => item.item_id == itemId);
    // Analytics.logEvent('CartUpdate', { origin: pageOrigin });
    if (product) {
        if (timeoutItems[itemId]) clearTimeout(timeoutItems[itemId].timeout);

        let { isAdd: isAddTimeout, toastId } = timeoutItems[itemId] || { isAdd: undefined, toastId: undefined };

        if (qty == 0) {
            toastId = toastSuccess({
                isAdd,
                isAddTimeout,
                product,
                toastId
            });

            if (timeoutItems[itemId]) {
                timeoutItems[itemId].toastId = toastId;
            }
            await remove(product.item_id);
        } else {
            const fn = async () => {
                try {
                    delete timeoutItems[itemId];
                    const result = await updateQtdItem(itemId, qty, option);

                    ReactGa4Service.onCartAddOrRemove({
                        isAdd: Boolean(isAdd),
                        product: { item_id: product.sku, quantity: qty, price, item_name: name }
                    });

                    if (result?.item_id) {
                        await updatePriceItem(result?.item_id, result?.price);
                    }
                    await Promise.all([
                        queryClient.resetQueries(['estimateShippingMethods']),
                        queryClient.resetQueries([CART])
                    ]);
                    // toastSuccess({
                    //     isAdd,
                    //     isAddTimeout,
                    //     product,
                    //     toastId
                    // });
                } catch {
                    toast.error(`O Produto ${product.name} não tem a quantidade ${qty} no estoque no momento!`, {
                        autoClose: false
                    });
                    queryClient.invalidateQueries([CART]);
                }
            };

            if (wait) {
                await fn();
            } else {
                // toastId = toastSuccess({
                //     isAdd,
                //     isAddTimeout,
                //     product,
                //     toastId
                // });
                timeoutItems[itemId] = createTimeOut(fn, 1000, toastId, isAdd);
            }
        }

        queryClient.setQueryData([CART], (old: Cart | undefined) => {
            const newItems = old?.items.map((product) =>
                product.item_id == itemId
                    ? {
                          ...product,
                          qty,
                          ...(price ? { price } : {})
                      }
                    : product
            ) as CartItem[];

            return {
                ...old,
                info: {
                    ...old?.info,
                    subtotal: getTotalPriceItems(newItems)
                },
                totalItems: getTotalItems(newItems),
                items: newItems
            } as Cart;
        });
    }
};

const checkStockItensCart = async (items: CartItem[]) => {
    const itemsNotInStock = items.filter((item) => item.quantity > 0 && item.qty > item.quantity);
    if (itemsNotInStock.length === 0) {
        return [];
    }

    // await Promise.allSettled(itemsNotInStock.map((item) => updateQtdItem(item.item_id, item.quantity)));
    const productsNames = itemsNotInStock.map((item) => item.name).join(', ');

    toast.error(
        // `Alguns produtos não tem a quantidade no estoque no momento! Eles foram alterados para quantidade máxima no momento`,
        `Os produtos ${productsNames} não tem a quantidade no estoque no momento! Eles foram alterados para quantidade máxima no momento`,
        { autoClose: false }
    );
};

const updateCart = async ({
    updateId = false,
    fromRetry = false,
    forceNewId = false,
    req,
    res,
    retryQtd = 0
}: IUpdateCart): Promise<Cart> => {
    const request = { req, res };
    let id = getCartIdStorage({ req, res });

    retryQtd++;
    try {
        if (updateId || forceNewId) {
            id = await initCart({ forceNewId: fromRetry || forceNewId, ...request });
        }
        let [items, info] = await Promise.all([list(undefined, request), getInfo(undefined, request)]);

        await checkStockItensCart(items);

        //quando não esta logado
        if (!info) {
            info = {
                subtotal: getTotalPriceItems(items),
                grand_total: getTotalPriceItems(items)
            };
        }

        return {
            id,
            items,
            info,
            totalItems: getTotalItems(items)
        } as Cart;
    } catch (err) {
        if (axios.isAxiosError(err) && err?.response?.status === 404 && retryQtd < 5) {
            return updateCart({ updateId: true, fromRetry: true, retryQtd, ...request });
        }

        return {
            id,
            items: [],
            info: {},
            totalItems: 0
        } as Cart;
    }
};
const updateInfoCart = async (): Promise<void> => {
    const info = await getInfo();

    queryClient.setQueryData(
        [CART],
        (old: Cart | undefined) =>
            ({
                ...old,
                info
            }) as Cart
    );
};
const refetchCart = async ({ updateId = false, forceNewId = false }: IRefetchCartParams = {}) => {
    const result = await updateCart({ updateId, forceNewId });
    queryClient.setQueryData(
        [CART],
        (old: Cart | undefined) =>
            ({
                ...old,
                ...result
            }) as Cart
    );
};
const remove = async (itemId: number, pageOrigin?: string) => {
    cartEvent.emit('onRemoveItems', { itemId });
    if (timeoutItems[itemId]) clearTimeout(timeoutItems[itemId].timeout);

    const cart = queryClient.getQueryData([CART]) as Cart;
    const product = cart.items.find((p) => p.item_id == itemId);

    const itemDatalayer = {
        isAdd: false,
        product: {
            item_id: product?.sku,
            item_name: product?.name,
            price: product?.price,
            quantity: product?.qty
        }
    };

    queryClient.setQueryData([CART], (old: Cart | undefined) => {
        const newItems = old?.items.filter((product) => product.item_id != itemId) as CartItem[];
        return {
            ...old,
            items: newItems ? newItems : [],
            info: {
                ...old?.info,
                subtotal: getTotalPriceItems(newItems)
            },
            totalItems: getTotalItems(newItems)
        } as Cart;
    });
    const result = await removeItem(itemId);
    queryClient.resetQueries(['estimateShippingMethods']);

    if (result) {
        ReactGa4Service.onCartAddOrRemove(itemDatalayer);
    }
    queryClient.resetQueries([CART_ITEMS]);
};
const clear = async () => {
    let cart = queryClient.getQueryData([CART]) as Cart;

    cart.items.map((product) => {
        if (timeoutItems[product.item_id]) clearTimeout(timeoutItems[product.item_id].timeout);
        removeItem(product.item_id);
    });
    queryClient.setQueryData(
        [CART],
        (old: Cart | undefined) =>
            ({
                ...old,
                info: {
                    ...old?.info,
                    subtotal: 0
                },
                totalItems: 0,
                items: []
            }) as Cart
    );
    return true;
};

const createSubscribe = (fn: SubscribeFn, options?: QueryObserverOptions<Cart>) => {
    const observer = new QueryObserver<Cart>(queryClient, {
        queryKey: [CART],
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        ...options
    });
    // queryClient.invalidateQueries([CART])
    const cart = queryClient.getQueryData([CART]) as Cart;
    fn(cart);
    return observer.subscribe(({ data }) => fn(data as Cart));
};

const getTotalItemsCart = () => {
    const cart = queryClient.getQueryData([CART]) as Cart;
    if (!cart || !cart.items) return 0;

    return cart.items.reduce((previousValue, currentValue) => {
        if (currentValue?.extension_attributes?.measure_type == String(EMeasureType.WEIGHT)) {
            return previousValue + 1;
        }

        return previousValue + currentValue.qty;
    }, 0);
};

const getMaxHourToDelivery = async () => {
    const { data } = await api.get<string>('V1/delivei/core/timegap');

    return parseInt(data);
};

const getInstallments = async (value: number) => {
    const { data } = await api.post<string[]>('V1/delivei/core/installments', {
        data: {
            store_id: 0,
            valor: value
        }
    });

    return data.map((item) => Number(item));
};

const getInstallmentsPagarme = async (value: number, type: string, axiosParams?: AxiosRequestConfig) => {
    const money = getFormattedValue({
        value: value?.toString(),
        mask: 'currencyDecimal'
    });

    const valueToString = money.replace(/\./g, '').replace(/,/g, '');

    const { data } = await api.get(`V1/pagarme/installments/brandbyamount/${type}/${valueToString}`, axiosParams);

    return data;
};

const setPaymentMethod = async (method: PaymentTypes, axiosParams?: AxiosRequestConfig) => {
    await api.post(
        `V1/carts/mine/set-payment-information`,
        {
            paymentMethod: {
                method
            }
        },
        axiosParams
    );

    await queryClient.resetQueries([CART]);
};

export default {
    initCart,
    getInstallments,
    getMaxHourToDelivery,
    getTotalItemsCart,
    findProduct,
    estimateShippingMethods,
    setCartIdStorage,
    getInfo,
    getCartIdStorage,
    addItem,
    removeItem,
    updateQtdItem,
    shippingInformation,
    transferItemsCartLogged,
    list,
    buyAgain,
    checkout,
    initCartLogged,
    addCoupon,
    removeCoupon,
    setAddress,
    updateCart,
    add,
    updateItem,
    remove,
    refetchCart,
    createSubscribe,
    updateInfoCart,
    executeAllRequest,
    hasExecuteRequest,
    getCartItems,
    creditCardCheckout,
    checkoutSubmit,
    setShippingInformation,
    placeOrder,
    getInstallmentsPagarme,
    getPixPaymentInfo,
    getBilletPaymentInfo,
    getBlockPurchaseCEP,
    clear,
    getProductsUnavailableCart,
    guestEstimateShippingMethods,
    setPaymentMethod
};
