import { UseQueryOptions } from '@tanstack/react-query';
import { WishlistService } from '../../services';
import { IS_BROWSER } from '../../services/query-client';
import { Wishlist } from '../../services/wishlist/types';
import { WISHLIST } from '../../utils/react-query-keys';
import { useQueryCancelToken } from '../use-query-cancel-token';

export const useWishlist = (params?: UseQueryOptions<Wishlist[]>) => {
    return useQueryCancelToken<Wishlist[]>([WISHLIST], (paramsAxios) => WishlistService.getAll(paramsAxios), {
        refetchOnMount: true,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        enabled: IS_BROWSER,
        ...params
    });
};
