import { FC, useEffect } from 'react';

import { EstablishmentService } from '../../../services';
import { NEXT_PUBLIC_HOME_BANNER_1, NEXT_PUBLIC_HOME_BANNER_MOBILE } from '../../../utils/envs';
import CouponsBanner from '../../molecules/Coupons-banner';
import ShippingBanner from '../../molecules/Shipping-banner';
import { HallProductsFeatured } from '../../organisms';
import BannerCarouselSlideShowThree from '../../organisms/Banner-carousel-slide-show-three';
import BannerCarouselSlideShowThreeInline from '../../organisms/Banner-carousel-slide-show-three-inline';
import MostSearchedCategories from '../../organisms/Most-searched-categories';
import PartnersCarouselSlide from '../../organisms/Partners-carousel-slide';
import Layout from '../Layout';
import LayoutFitter from '../Layout-fitter';
import HomeBannerProducts from './Banner-products';
import { Container } from './styles';
import { IHomeProps } from './types';
import { IS_BROWSER } from '../../../services/query-client';
import { HALL_FEATURED_FILTER_2, HALL_FEATURED_FILTER_3 } from '../../organisms/Hall-products-featured/types';
import { useBlockHallHome } from '../../../hooks/use-block-hall-home';
import useAdoptCallbacks from '../../../hooks/use-adopt-callbacks';

const Home: FC<IHomeProps> = (props) => {
    const { data: hallHome } = useBlockHallHome();

    const firstHall = hallHome?.[0];
    const secondHall = hallHome?.[1];
    const thirdHall = hallHome?.[2];
    const restHalls = hallHome?.slice(3);

    // useEffect(() => {
    //     if (navigator.geolocation) {
    //         navigator.geolocation.getCurrentPosition(
    //             (position) => {
    //                 console.log({
    //                     lat: position.coords.latitude,
    //                     lng: position.coords.longitude
    //                 });
    //             },
    //             (err) => {}
    //         );
    //     } else {
    //     }
    // }, []);

    useAdoptCallbacks(
        () => {
            if (IS_BROWSER) {
                EstablishmentService.checkEstablishment(props.detectedIp);
            }
        },
        () => {
            if (IS_BROWSER) {
                EstablishmentService.checkEstablishment(props.detectedIp);
            }
        }
    );

    return (
        <Layout>
            <Container>
                <div style={{ gridArea: 'Banner1' }}>
                    <BannerCarouselSlideShowThreeInline id={NEXT_PUBLIC_HOME_BANNER_1} />
                </div>
                <div className="pt-8 md:pt-7" style={{ gridArea: 'ShippingBanner' }}>
                    <ShippingBanner />
                </div>
                <div className="pt-8 md:pt-16" style={{ gridArea: 'MostSearchedCategories' }}>
                    <LayoutFitter className="!px-0">
                        <MostSearchedCategories />
                    </LayoutFitter>
                </div>
                <div className="pt-11 md:pt-[88px]" style={{ gridArea: 'HomeBannerProducts' }}>
                    <HomeBannerProducts />
                </div>
                {firstHall && (
                    <div className="pt-8 md:pt-12" style={{ gridArea: 'HallProductsFeatured' }}>
                        <LayoutFitter withoutPaddingMobile>
                            <HallProductsFeatured origin={'hall-01'} id="0" hallHome={firstHall} />
                        </LayoutFitter>
                    </div>
                )}
                {/* <div className="pt-6 md:pt-12" style={{ gridArea: 'SellersCarouselSlide' }}>
                    <LayoutFitter>
                        <SellersCarouselSlide />
                    </LayoutFitter>
                </div> */}
                <div className="pt-8 md:pt-16" style={{ gridArea: 'BannerCarouselSlideShowThree' }}>
                    <LayoutFitter>
                        <BannerCarouselSlideShowThree
                            widthImage={432}
                            heightImage={217}
                            id={NEXT_PUBLIC_HOME_BANNER_MOBILE}
                        />
                    </LayoutFitter>
                </div>
                {secondHall && (
                    <div className="pt-7 md:pt-8" style={{ gridArea: 'HallProductsFeatured2' }}>
                        <LayoutFitter withoutPaddingMobile>
                            <HallProductsFeatured origin={'hall-02'} id="1" hallHome={secondHall} />
                        </LayoutFitter>
                    </div>
                )}
                {thirdHall && (
                    <div className="pt-14 md:pt-14" style={{ gridArea: 'HallProductsFeatured3' }}>
                        <LayoutFitter withoutPaddingMobile>
                            <HallProductsFeatured origin={'hall-03'} id="2" hallHome={thirdHall} />
                        </LayoutFitter>
                    </div>
                )}
                <div className="pt-7 md:pt-12" style={{ gridArea: 'PartnersCarouselSlide' }}>
                    <LayoutFitter>
                        <PartnersCarouselSlide />
                    </LayoutFitter>
                </div>
                {restHalls && restHalls.length > 0 && (
                    <div style={{ gridArea: 'restHalls' }} className="mb-12">
                        {restHalls.map((hall, index) => (
                            <div className={`pt-14 md:pt-14 test-${index + 3}`} key={`hall-${index + 3}`}>
                                <LayoutFitter withoutPaddingMobile>
                                    <HallProductsFeatured
                                        origin={`hall-${index + 3}`}
                                        id={`${index + 3}`}
                                        hallHome={hall}
                                    />
                                </LayoutFitter>
                            </div>
                        ))}
                    </div>
                )}
                <div className="pt-9 sm:pt-12 sm:pb-12" style={{ gridArea: 'CouponsBanner' }}>
                    <CouponsBanner />
                </div>
            </Container>
        </Layout>
    );
};

export default Home;
