import { capitalize } from '@mui/material';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useCategories, useUserLogged } from '../../../../../hooks';
import { useBlockConfig } from '../../../../../hooks/use-block-config';
import { useUserInfo } from '../../../../../hooks/use-user-info';
import { CategoryService, ProductService, WishlistService } from '../../../../../services';
import { Categories } from '../../../../../services/category/types';
import { IProduct, IProductExtensionAttributesLinks } from '../../../../../services/product/types';
import { IWishlist } from '../../../../../services/wishlist/types';
import { IconOrder } from '../../../../atoms';
import ModalPortal from '../../../../atoms/Modal-portal';
import IncludeItemWishlist from '../../../../molecules/Include-item-wishlist';
import BreadcrumbComponent, { IBreadcrumbProps } from '../../../../organisms/Breadcrumb';
import LayoutFitter from '../../../Layout-fitter';
import { Badges, Button, Buttons, Container } from './styles';
import { BadgeSize } from '../../../../atoms/Badge/types';
import BadgeProductDiscount from '../../../../atoms/Badge-product-discount';

// Função para buscar o nível mais profundo da categoria do produto
function getDeepestCategoryLevel(
    categoryLinks: IProductExtensionAttributesLinks[],
    categories: Categories[]
): string | undefined {
    let deepestLevel: Categories | undefined;
    for (const categoryLink of categoryLinks) {
        const category = CategoryService.getCategoryFromCategories(categoryLink.category_id, categories);
        if (category && (!deepestLevel || category.level > deepestLevel.level)) {
            deepestLevel = category;
        }
    }
    return deepestLevel?.id;
}

interface BreadCrumbProps {
    data: IProduct;
}

function Breadcrumb({ data }: BreadCrumbProps) {
    const { data: categories } = useCategories({ refetchOnMount: false });
    const router = useRouter();
    const [showModalWishlist, setShowModalWishlist] = useState<boolean>(false);
    const [hasPostRequestFired, setHasPostRequestFired] = useState(false);

    const { data: isLogged } = useUserLogged();
    useUserInfo();
    useBlockConfig();

    useEffect(() => {
        const productId = localStorage.getItem('wish-p-id');
        const lastUrl = localStorage.getItem('prev-url');
        const origin = localStorage.getItem('wish-origin');
        if (productId && lastUrl && origin == 'pdp') {
            handleClickModal();
        }
    }, []);

    if (!data || !data.extension_attributes?.category_links || !categories) return null;

    const firstCategory = getDeepestCategoryLevel(data?.extension_attributes?.category_links, categories);

    if (!firstCategory) return null;

    const breadcrumb = CategoryService.getBreadcrumb(categories, firstCategory.toString());

    if (!breadcrumb || !breadcrumb.length) return null;

    const categoriesProduct = [
        { label: 'Página Inicial', url: '/' },
        ...breadcrumb.map((categoryProduct) => {
            return {
                label: categoryProduct.name,
                url: ProductService.getUrlSearchProduct(categoryProduct.url)
            };
        }),

        { label: capitalize(data?.name || '') }
    ];

    const handleClickModal = async () => {
        const wishlistId = await findDefaultWishlist();

        if (!isLogged) {
            localStorage.setItem('wish-p-id', data.sku);
            localStorage.setItem('prev-url', window.location.pathname);
            localStorage.setItem('wish-origin', 'pdp');
            router.push('/login');
            return;
        }
        localStorage.removeItem('wish-p-id');
        localStorage.removeItem('prev-url');
        localStorage.removeItem('wish-origin');

        if (wishlistId) {
            setHasPostRequestFired(false);
            toast.success(
                <div className="flex flex-row justify-between">
                    <span>Adicionado aos Meus Favoritos</span>
                    <a
                        onClick={() => {
                            setShowModalWishlist(true);
                            setHasPostRequestFired(true);
                        }}
                    >
                        INCLUIR NA LISTA
                    </a>
                </div>,
                {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: true,
                    className: 'md:w-[500px] text-sm',
                    onClose: () => {
                        if (!showModalWishlist && !hasPostRequestFired) {
                            addToDefaultWishlist(wishlistId);
                            setHasPostRequestFired(true);
                        }
                    }
                }
            );
        }
    };

    const findDefaultWishlist = async () => {
        const response = await WishlistService.all();
        const wishlist = response.find((item: IWishlist) => item.wishlist_name === 'My Wishlist' || 'Principal');
        return wishlist ? wishlist.wishlist_id : null;
    };

    const addToDefaultWishlist = async (wishlistId: any) => {
        if (!showModalWishlist) {
            try {
                await WishlistService.addItem(Number(wishlistId), Number(data.sku));
            } catch (error: any) {
                if (error.response.data.message?.toLowerCase().includes('without stock')) {
                    toast.error(
                        'Não foi possível adicionar o produto à lista de compras, pois está sem estoque no momento'
                    );
                } else {
                    toast.error(error.response.data.message);
                }
            }
        }
    };

    return (
        <>
            <LayoutFitter>
                <Container>
                    <BreadcrumbComponent items={categoriesProduct as IBreadcrumbProps['items']} />
                    <Buttons>
                        <Button type="button" onClick={handleClickModal}>
                            <IconOrder className="w-6 h-6 text-black" />
                            <div>Adicionar à Lista de Compras</div>
                        </Button>
                        {/* <Button type="button">
                        <IconOrder className="w-6 h-6 text-black" />
                        <div>Adicionar a Lista de Compras</div>
                    </Button> */}
                    </Buttons>
                    {data.discount_percentage && data.discount_percentage > 0 && (
                        <Badges>
                            {/* <BadgeProductFeature size={BadgeSize.SMALL} /> */}
                            {/* <BadgeProductFreeShipping size={BadgeSize.SMALL} /> */}
                            <BadgeProductDiscount size={BadgeSize.SMALL} value={`-${data.discount_percentage}`} />
                        </Badges>
                    )}
                </Container>
            </LayoutFitter>
            <ModalPortal>
                {showModalWishlist && (
                    <IncludeItemWishlist
                        onClose={() => setShowModalWishlist(false)}
                        onConfirm={() => setHasPostRequestFired(true)}
                        product={data.sku}
                    />
                )}
            </ModalPortal>
        </>
    );
}

export default Breadcrumb;
