import dynamic from 'next/dynamic';
import MyAccountLayout from '../../organisms/My-account-layout';
import Layout from '../Layout';
import LayoutFitter from '../Layout-fitter';
import { ProductsListContainer } from './styles';
const WishlistList = dynamic(() => import('../../organisms/Wishlist-list'), { ssr: false });

const WishlistDetail = () => {
    return (
        <Layout>
            <LayoutFitter>
                <MyAccountLayout title="Minhas listas">
                    <ProductsListContainer>
                        <WishlistList />
                    </ProductsListContainer>
                </MyAccountLayout>
            </LayoutFitter>
        </Layout>
    );
};

export default WishlistDetail;
