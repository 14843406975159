import { IS_BROWSER, queryClient } from '../query-client';
import { IProduct } from '../product/types';
import { Cart, CartShippingMethod } from '../cart/types';
import { PaymentTypes } from '../payment/types';
import { CART } from '../../utils/react-query-keys';
import { IOnCartAddOrRemoveParams, IItem, IProducItems } from './types';
import { Order, OrderProductItem } from '../order/types';
import { Value } from '../../components/organisms/Modal-cart-footer/styles';

declare global {
    interface Window {
        dataLayer: Record<string, any>[];
    }
}

const checkInicialized = () => {
    return IS_BROWSER && window.dataLayer;
};

function formatPrice(price: any) {
    let formattedPrice: any;
  
    if (typeof price === 'string') {
      formattedPrice = parseFloat(price).toFixed(2);
    } else if (typeof price === 'number') {
      formattedPrice = price.toFixed(2);
    } else {
        formattedPrice = price
    }
  

    return parseFloat(formattedPrice);
}

const onProductClick = (product: IProduct) => {
    if (!checkInicialized()) return;

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event: 'select_item',
        ecommerce: {
            items: [
                {
                    item_id: product.id,
                    item_sku: product.sku,
                    item_name: product.name,
                    item_price: formatPrice(product.price)
                }
            ]
        }
    });
};

const onLoginInfo = () => {
    window.dataLayer.push({
        event: 'login',
        ecommerce: {
            method: 'Google'
        }
    });
};

const onProductView = (product: IProduct) => {
    if (!checkInicialized()) return;

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event: 'view_item',
        ecommerce: {
            items: [
                {
                    item_id: product.id,
                    item_sku: product.sku,
                    item_name: product.name,
                    item_price: formatPrice(product.price)
                }
            ]
        }
    });
};

const onViewItem = (items: IItem, value: number) => {
    if (!checkInicialized()) return;

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event: 'view_item',
        ecommerce: {
            currency: 'BRL',
            value,
            items: {
                item_id: items.item_id,
                item_name: items.item_name,
                price: formatPrice(items.price)
            }
        }
    });
};

const onViewCart = (items: IProducItems[], value: number) => {
    if (!checkInicialized()) return;

    const itemsData = items.map((value: IProducItems) => {
        return {
            item_id: value.item_id,
            item_name: value.item_name,
            price: formatPrice(value.price),
            quantity: value.quantity
        };
    });

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event: 'view_cart',
        ecommerce: {
            currency: 'BRL',
            items: itemsData,
            value: formatPrice(value)
        }
    });
};

const onCartAddOrRemove = ({ isAdd, product }: IOnCartAddOrRemoveParams) => {
    if (!checkInicialized()) return;
    window.dataLayer.push({ ecommerce: null });
    const cart = queryClient.getQueryData([CART]) as Cart;

    window.dataLayer.push({
        event: isAdd ? 'add_to_cart' : 'remove_from_cart',
        ecommerce: {
            currency: 'BRL',
            value: formatPrice(product.price),
            items: [
                {
                    item_id: product.item_id,
                    item_name: product.item_name,
                    price: formatPrice(product.price),
                    quantity: product.quantity
                }
            ]
        }
    });
};

const onViewItemList = (products: IProduct[]) => {
    if (!checkInicialized()) return;
    window.dataLayer.push({ ecommerce: null });
    const items = products.map((item) => {
        return {
            item_id: item.sku,
            price: formatPrice(item.price),
            item_name: item.name,
            quantity: item.extension_attributes?.stock_item?.qty
        };
    });

    window.dataLayer.push({
        event: 'view_item_list',
        ecommerce: {
            items
        }
    });
};

const onBeginCheckout = () => {
    if (!checkInicialized()) return;

    window.dataLayer.push({ ecommerce: null });

    const cart = queryClient.getQueryData([CART]) as Cart;
    if (!cart) return null;

    const items = getItemsProducts();

    window.dataLayer.push({
        event: 'begin_checkout',
        ecommerce: {
            currency: 'BRL',
            value: formatPrice(cart?.info?.grand_total),
            coupon: cart?.info?.coupon_code,
            items
        }
    });
};

const getItemsProducts = () => {
    const cart = queryClient.getQueryData([CART]) as Cart;

    return cart?.items?.map((item) => {
        return {
            item_id: item.sku,
            item_name: item.name,
            item_category: item.product_type,
            price: formatPrice(item.price),
            quantity: item.qty
        };
    });
};

const onPurchase = (orderId: number) => {
    if (!checkInicialized()) return;

    const cart = queryClient.getQueryData([CART]) as Cart;
    const data = getItemsProducts();

    window.dataLayer.push({ ecommerce: null });

    window.dataLayer.push({
        event: 'purchase',
        ecommerce: {
            cart_id: cart.id,
            value: formatPrice(cart.info.grand_total),
            tax: cart.info.tax_amount,
            transaction_id: orderId,
            shipping: cart.info.shipping_amount,
            currency: 'BRL',
            items: data
        }
    });
};

const onPurchaseEvent = (orderId: number, orderInfo: Order) => {
    if (!checkInicialized()) return;

    const itemsData = orderInfo.items.map((value: OrderProductItem) => {
        return {
            item_id: value.sku,
            item_name: value.name,
            coupon: orderInfo.coupon_code,
            price: formatPrice(value.price),
            discount: formatPrice(value.base_discount_amount),
            quantity: value.qty_ordered
        };
    });

    window.dataLayer.push({ ecommerce: null });

    window.dataLayer.push({
        event: 'purchase',
        ecommerce: {
            transaction_id: orderInfo.increment_id,
            value: formatPrice(orderInfo.base_grand_total),
            tax: 0,
            shipping: orderInfo.base_shipping_amount,
            currency: 'BRL',
            coupon: orderInfo.coupon_code,
            items: itemsData
        }
    });
};

const onAddPaymentInfo = (payment: string | undefined, orderInfo: Order) => {
    if (!checkInicialized()) return;
    window.dataLayer.push({ ecommerce: null });

    const cart = queryClient.getQueryData([CART]) as Cart;
    const itemsData = orderInfo.items.map((value: OrderProductItem) => {
        return {
            item_id: value.sku,
            item_name: value.name,
            coupon: orderInfo.coupon_code,
            price: formatPrice(value.price),
            discount: formatPrice(value.base_discount_amount),
            quantity: value.qty_ordered
        };
    });

    window.dataLayer.push({
        event: 'add_payment_info',
        ecommerce: {
            currency: 'BRL',
            value: formatPrice(orderInfo.base_grand_total),
            coupon: orderInfo.coupon_code,
            payment_type: payment,
            items: itemsData
        }
    });
};

const onAddShippingInfo = (items: any, shipping: any, resume: any) => {
    if (!checkInicialized()) return;

   window.dataLayer.push({ ecommerce: null });

   const itemsData = items.map((value: any) => {
        return {
            item_id: value.sku,
            item_name: value.name,
            price: formatPrice(value.price),
            quantity: value.qty
        };
    });

    window.dataLayer.push({
        event: 'add_shipping_info',
        ecommerce: {
            currency: 'BRL',
            value: formatPrice(resume.grand_total),
            shipping_tier: shipping?.method_title,
            items: itemsData
        }
    });
};

export default {
    onProductClick,
    onCartAddOrRemove,
    onViewItemList,
    onBeginCheckout,
    onPurchase,
    onProductView,
    onAddPaymentInfo,
    onAddShippingInfo,
    onLoginInfo,
    onViewCart,
    onViewItem,
    onPurchaseEvent
};
