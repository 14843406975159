import { parse as parseHimalaya } from 'himalaya';
import { ElasticService } from '..';
import { ICookieCtx } from '../../utils/cookie';
import { NEXT_PUBLIC_URL_API } from '../../utils/envs';
import blockService from '../block';
import { IBoolQuery } from '../elastic/types';
import { IProduct } from '../product/types';
import { BannerImage } from './types';

export enum BannerType {
    PRODUTO = 'PRODUTO',
    CATEGORIA = 'CATEGORIA',
    PAGE = 'PAGE',
    LINK = 'LINK'
}

export interface Banner {
    type: BannerType | string;
    image: string;
    param: string;
    skuHighlight?: string[];
}
export interface BannerResponsive extends Banner {
    imageMobile: string;
}
export interface BannerWithProduct extends Partial<BannerResponsive> {
    products?: IProduct[];
    title?: string;
    type: Banner['type'];
    param: Banner['param'];
    paramDestiny: Banner['param'];
}

const parse = (content: string): Banner[] | [] => {
    const parse = parseHimalaya(content);
    const table = parse.find((v: any) => v.tagName == 'table');
    if (!table) {
        return [];
    }
    const tbody = table.children.find((v: any) => v.tagName == 'tbody');
    if (!tbody) {
        return [];
    }
    const trs = tbody.children.filter((v: any) => v.tagName == 'tr').slice(1);
    return trs.map((tr: any) => {
        const [image, type, param, skuHighlightStr] = tr.children
            .filter((v: any) => v.tagName == 'td')
            .map((td: any) => td.children[0]) as any[];

        const imageSrc = image?.attributes
            ?.find((a: any) => a.key == 'src')
            ?.value.replace(/&quot;/g, '"')
            .match(/\"(.*?)\"/)[1];
        const typeStr = (type.content || '')
            .toUpperCase()
            .replace(/&NBSP;/g, ' ')
            .trim();
        const paramStr = typeStr == BannerType.CATEGORIA ? param?.content?.split(',') : param?.content;
        const skuHighlightReplaced = skuHighlightStr?.content?.replace(/&nbsp;/g, '')?.trim();
        const skuHighlight = skuHighlightReplaced?.length
            ? skuHighlightReplaced.split(',').map((sku: string) => sku.trim())
            : [];

        return {
            type: typeStr,
            param: paramStr,
            image: `${NEXT_PUBLIC_URL_API}/media/${imageSrc}`,
            skuHighlight
        } as any;
    });
};

const parseResponsive = (content: string): BannerResponsive[] | [] => {
    const parse = parseHimalaya(content);
    const table = parse.find((v: any) => v.tagName == 'table');
    if (!table) {
        return [];
    }
    const tbody = table.children.find((v: any) => v.tagName == 'tbody');
    if (!tbody) {
        return [];
    }
    const trs = tbody.children.filter((v: any) => v.tagName == 'tr').slice(1);
    return trs.map((tr: any) => {
        const [image, imageMobile, type, param, skuHighlightStr] = tr.children
            .filter((v: any) => v.tagName == 'td')
            .map((td: any) => td.children[0]) as any[];

        const imageSrc = image?.attributes
            ?.find((a: any) => a.key == 'src')
            ?.value.replace(/&quot;/g, '"')
            .match(/\"(.*?)\"/)[1];
        const imageMobileSrc = imageMobile?.attributes
            ?.find((a: any) => a.key == 'src')
            ?.value.replace(/&quot;/g, '"')
            .match(/\"(.*?)\"/)[1];
        const typeStr = type.content
            ?.toUpperCase()
            .replace(/(&NBSP;|&nbsp;)/g, '')
            .replace(/[\r\n]/g, '')
            .trim();
        const paramStr = typeStr == BannerType.CATEGORIA ? param?.content?.split(',') : param?.content;
        const skuHighlightReplaced = skuHighlightStr?.content?.replace(/&nbsp;/g, '')?.trim();
        const skuHighlight = skuHighlightReplaced?.length
            ? skuHighlightReplaced.split(',').map((sku: string) => sku.trim())
            : [];

        return {
            type: typeStr,
            param: paramStr,
            image: `${NEXT_PUBLIC_URL_API}/media/${imageSrc}`,
            imageMobile: `${NEXT_PUBLIC_URL_API}/media/${imageMobileSrc}`,
            skuHighlight
        } as any;
    });
};

const findTag = (element: any, tag: string) => {
    if (
        (element?.tagName === tag && element?.type == 'element') ||
        (tag == 'text' && element?.content && element?.content.trim().length)
    ) {
        return element;
    }

    if (element?.children && element.children.length) {
        for (let el of element.children) {
            const result: any = findTag(el, tag);
            if (result) {
                return result;
            }
        }
    }
};
const parseImageSrc = (src: string) => {
    let result = src.match(/\"(.*?)\"/);
    if (result && 1 in result) {
        return result[1];
    }
    result = src.match(/"(.*?)"/);

    return result && 1 in result ? result[1] : '';
};
const parseImages = (content: string): BannerImage[] => {
    const parse = parseHimalaya(content);
    const table = parse.find((v) => v.tagName == 'table');
    if (!table) {
        return [];
    }
    const tbody = table.children.find((v: any) => v.tagName == 'tbody');
    if (!tbody) {
        return [];
    }
    const trs = tbody.children.filter((v: any) => v.tagName == 'tr').slice(1);
    return trs.map((tr: any) => {
        const [tdImage, tdTitle] = tr.children.filter((v: any) => v.tagName == 'td').map((td: any) => td);
        const image = findTag(tdImage, 'img');
        const title = findTag(tdTitle, 'text');

        const imageSrc = parseImageSrc(image?.attributes.find((a: any) => a.key == 'src').value);
        const titleStr = title.content.replace(/&nbsp;/g, ' ').trim();
        return {
            title: titleStr,
            image: `${NEXT_PUBLIC_URL_API}/media/${imageSrc}`
        };
    });
};

const getById = async (id: string): Promise<Banner[] | []> => {
    const data = await blockService.getById(id);
    if (data && data.active) {
        return parse(data.content);
    }

    return [];
};

const getBannerResponsiveById = async (id: string): Promise<BannerResponsive[] | []> => {
    const data = await blockService.getById(id);
    if (data && data.active) {
        return parseResponsive(data.content);
    }

    return [];
};
const getBannerWithProducts = async (id: string, cookiesParams?: ICookieCtx): Promise<BannerWithProduct> => {
    const data = await blockService.getById(id, cookiesParams);
    if (data && data.active) {
        const parse = parseHimalaya(data.content);
        const table = parse.find((v: any) => v.tagName == 'table');
        if (!table) {
            return { products: [], type: '', param: '', paramDestiny: '' };
        }
        const tbody = table.children.find((v: any) => v.tagName == 'tbody');
        if (!tbody) {
            return { products: [], type: '', param: '', paramDestiny: '' };
        }
        const [trTitle, trType, trParam, trParamDestiny, trImage, trImageMobile] = tbody.children.filter(
            (v: any) => v.tagName == 'tr'
        );

        const title = trTitle.children.filter((v: any) => v.tagName == 'td')[1].children[0] as any;
        const param = trParam.children.filter((v: any) => v.tagName == 'td')[1].children[0] as any;
        const paramDestiny = trParamDestiny.children.filter((v: any) => v.tagName == 'td')[1].children[0] as any;
        const type = trType.children.filter((v: any) => v.tagName == 'td')[1].children[0] as any;
        const image = trImage.children.filter((v: any) => v.tagName == 'td')[1].children[0] as any;
        const imageMobile = trImageMobile.children.filter((v: any) => v.tagName == 'td')[1].children[0] as any;

        const imageSrc = image?.attributes
            ?.find((a: any) => a.key == 'src')
            ?.value.replace(/&quot;/g, '"')
            .match(/\"(.*?)\"/)[1];
        const imageMobileSrc = imageMobile?.attributes
            ?.find((a: any) => a.key == 'src')
            ?.value.replace(/&quot;/g, '"')
            .match(/\"(.*?)\"/)[1];
        const typeStr = type.content
            .toUpperCase()
            .replace(/&NBSP;/g, ' ')
            .trim();
        const titleStr = title.content
            .replace(/(&NBSP;|&nbsp;)/g, ' ')
            .replace(/[\r\n]/g, '')
            .trim();
        const paramStr = [BannerType.CATEGORIA, BannerType.PRODUTO].includes(typeStr)
            ? param?.content?.split(',')
            : param?.content;
        const paramDestinyStr = [BannerType.CATEGORIA, BannerType.PRODUTO].includes(typeStr)
            ? paramDestiny?.content?.split(',')
            : paramDestiny?.content;

        const params = {
            type: typeStr,
            param: paramStr,
            paramDestiny: paramDestinyStr,
            image: `${NEXT_PUBLIC_URL_API}/media/${imageSrc}`,
            imageMobile: `${NEXT_PUBLIC_URL_API}/media/${imageMobileSrc}`,
            title: titleStr
        } as any;

        let filterGroups: any = {
            bool: {
                // must: [
                //     {
                //         term: {
                //             status_stock: '1'
                //         }
                //     }
                // ],
                filter: [],
                should: []
            }
        };

        let sort = null;

        if (params.type === BannerType.CATEGORIA) {
            filterGroups.bool.filter.push({
                terms: {
                    category_ids: params.param
                }
            });
            sort = [
                {
                    [`position_category_${params.param}`]: {
                        order: 'asc'
                    }
                }
            ];
        } else if (params.type === BannerType.PRODUTO) {
            filterGroups.bool.must.push({
                query_string: {
                    query: params.param.join(' OR '),
                    fields: ['sku']
                }
            });
        }

        try {
            const response = await ElasticService.getProducts({
                filter: filterGroups as IBoolQuery,
                limit: 4,
                page: 1,
                cookiesParams,
                sort
            });

            return {
                ...params,
                products: response.items
            };
        } catch (e) {
            console.error(e);
            return {
                ...params,
                products: []
            };
        }
    }

    return {
        products: [],
        type: '',
        param: '',
        paramDestiny: ''
    };
};

const getBannerImagesById = async (id: string): Promise<BannerImage[] | []> => {
    const data = await blockService.getById(id);
    if (data) {
        return parseImages(data.content);
    }

    return [];
};

export default {
    getById,
    getBannerResponsiveById,
    getBannerImagesById,
    getBannerWithProducts
};
