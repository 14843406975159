import { ICookieCtx } from '../../utils/cookie';
import { LAST_URL } from '../../utils/cookies-keys';
import { ADDRESS, CART, ESTABLISHMENT, USER_INFO, USER_LOGGED } from '../../utils/react-query-keys';
import addressService from '../address';
import api, { apiNext } from '../api';
import cookieService from '../cookie';
import establishmentService from '../establishment';
import { queryClient } from '../query-client';
import routerService from '../router';
import userService from '../user';
import {
    IrequestNewToken,
    IUserCustomerIdStorageParams,
    IUsernamePasswordStorageParams,
    IUserTokenStorageParams,
    LoginResponse,
    StorageUsernamePassword,
    UserToken
} from './types';

const setUserTokenStorage = (token: UserToken | null, params?: IUserTokenStorageParams) => {
    cookieService.setUserTokenStorage(token, params);
};
const getUserTokenStorage = (params?: IUserTokenStorageParams): null | UserToken => {
    return cookieService.getUserTokenStorage(params);
};
const setUsernamePasswordStorage = (
    usernamePassword: StorageUsernamePassword | null,
    params?: IUsernamePasswordStorageParams
) => {
    return cookieService.setUsernamePasswordStorage(usernamePassword, params);
};
const getUserCustomerIdStorage = (params?: IUserCustomerIdStorageParams): null | string => {
    return cookieService.getUserCustomerIdStorage(params);
};
const setUserCustomerIdStorage = (id: string | null, params?: IUserCustomerIdStorageParams) => {
    return cookieService.setUserCustomerIdStorage(id, params);
};
const getUsernamePasswordStorage = (params?: IUsernamePasswordStorageParams): null | StorageUsernamePassword => {
    return cookieService.getUsernamePasswordStorage(params);
};
const requestNewToken = async (params?: IrequestNewToken): Promise<UserToken | null> => {
    try {
        const userStorage = getUsernamePasswordStorage({ req: params?.req, res: params?.res });
        const username = userStorage ? userStorage.username : null;
        const password = userStorage ? userStorage.password : null;
        if (username && password) {
            return await getTokenRequest(username, password);
        }

        return null;
    } catch (e) {
        return null;
    }
};
const getTokenRequest = async (username: string, password: string): Promise<UserToken> => {
    const { data: token } = await api.post('V1/integration/customer/token', {
        username,
        password
    });

    return token;
};

const login = async (username: string, password: string): Promise<LoginResponse> => {
    const cartService = require('../cart').default;
    const token: any = await getTokenRequest(username, password);

    if (token?.success === false) {
        throw new Error(token?.message);
    }

    if (token) {
        setUserTokenStorage(token);
        api.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        apiNext.defaults.headers.common['Authorization'] = 'Bearer ' + token;

        const [info, cartIdBefore] = await Promise.all([userService.getInfo(), cartService.getCartIdStorage()]);

        if (cartIdBefore && info) {
            await cartService.transferItemsCartLogged(cartIdBefore, info.id, info.store_id).catch((e: any) => {});
        }
        if (info?.addresses?.length && info.default_shipping) {
            const address = info.addresses.find((address) => address.id == info.default_shipping);

            if (address && address.postcode) {
                const list = await establishmentService.checkCoverageEstablishment(address.postcode);
                establishmentService.setEstablishmentStorage({
                    zipCode: address.postcode,
                    list
                });
            }
        }

        queryClient.setQueryData([USER_INFO], info);
        queryClient.invalidateQueries([USER_LOGGED]);
        setUserCustomerIdStorage(info?.id?.toString() as string);
        setUsernamePasswordStorage({
            username,
            password
        });

        await Promise.all([
            queryClient.prefetchQuery([CART], () => cartService.updateCart({ updateId: true, forceNewId: true }), {
                staleTime: 0
            }),
            queryClient.fetchQuery([ADDRESS], () => addressService.initAddress(), { staleTime: 0 })
        ]);

        addressService.updateQueryData({ zipcode: null });

        return token;
    }

    return null;
};

const logout = async () => {
    localStorage.removeItem('wish-p-id');
    localStorage.removeItem('prev-url');
    localStorage.removeItem('wish-origin');
    api.defaults.headers.common['Authorization'] = '';
    apiNext.defaults.headers.common['Authorization'] = '';
    setUsernamePasswordStorage(null);
    setUserTokenStorage(null);
    setTimeout(async () => {
        await Promise.all([
            localStorage.removeItem('@delivei:last_method'),
            cookieService.setUserCustomerIdStorage(null),
            cookieService.setCartIdStorage(null),
            cookieService.setShippingMethod(null),
            establishmentService.setEstablishmentStorage({
                list: [],
                zipCode: ''
            })
        ]);
        await queryClient.invalidateQueries();
        await queryClient.removeQueries();
        await queryClient.resetQueries();
        await queryClient.clear();
        routerService.toRoute({ options: { url: '/', replace: true } });
    }, 50);
};
const checkLogged = (cookieParams?: ICookieCtx): boolean => {
    return Boolean(getUserTokenStorage(cookieParams));
};

const setLastUrl = () => {
    window.localStorage.setItem('last-url', window.location.pathname + window.location.hash);
};
const getLastUrl = () => {
    return window.localStorage.getItem('last-url');
};
const getLastUrlCookie = () => {
    const findCookieRegex = new RegExp(`(${LAST_URL}=)+[^;]*;`);
    const replaceRegex = new RegExp(`(${LAST_URL}=)|;`, 'g');
    const lastUrl = document.cookie.match(findCookieRegex);
    if (lastUrl) {
        return lastUrl ? lastUrl[0].replace(replaceRegex, '') : null;
    }
};
export default {
    getUserCustomerIdStorage,
    getUsernamePasswordStorage,
    logout,
    setLastUrl,
    getLastUrl,
    setUserTokenStorage,
    checkLogged,
    getTokenRequest,
    login,
    setUsernamePasswordStorage,
    setUserCustomerIdStorage,
    getUserTokenStorage,
    getLastUrlCookie,
    requestNewToken
};
