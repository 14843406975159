import { motion } from 'framer-motion';
import tw, { styled } from 'twin.macro';
import { Image } from '../../atoms';

export const Container = styled(motion.div)`
    ${tw`
        flex
        flex-col
        gap-4
        border-[0.5px]
        border-[#DADADA]
        p-4
        relative
        // min-h-[352px]
        rounded-12
        w-full
        md:rounded-16
    `}
`;
export const ContentContainer = styled(motion.div)`
    ${tw`
        flex
        flex-row
        gap-4
        relative
        w-full
    `}
`;

export const ImageContainer = styled(motion.div)`
    ${tw`
        relative
      
        h-[80px]
        w-[80px]
    `}
`;

export const Img = styled(Image)`
    ${tw`
       object-cover
    `}
`;

export const Title = styled.div`
    ${tw`
        line-clamp-2
        font-semibold
        text-black
        text-12
        min-h-[32px]
        md:min-h-[40px]
        md:text-14
    `}
`;

export const Content = styled.div`
    ${tw`
        flex
        flex-col
        gap-2
    `}
`;

export const PriceFrom = styled.div`
    ${tw`
        text-black
        font-light
        flex
        items-center
        line-through
        text-10
        min-h-[12.5px]
        md:text-12
        md:min-h-[16px]
    `}
`;
export const Price = styled.div`
    ${tw`
        text-black
        font-bold
        flex
        items-center
        text-18
        min-h-[32px]
        md:min-h-[25px]
        md:text-22
    `}
`;

export const Installments = styled.div`
    ${tw`
        font-light
        text-black
        text-10
        md:text-12
        min-h-[16px]
    `}
`;
