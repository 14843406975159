import React, { FC } from 'react';
import { twColors } from '../../../utils/get-config-tailwind';
import { IconSpinner } from '../../atoms';
import { Container } from './styles';

interface ILoadingCustomProps {
    isLoading: boolean;
}

const LoadingCustom: FC<ILoadingCustomProps> = ({ isLoading }) => {
    if (!isLoading) return null;

    return (
    <div className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-70 z-50">
      <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-green-500 text-primary"></div>
    </div>
  );
};

export default LoadingCustom;
