import tw, { styled } from 'twin.macro';
import { TextNunitoBold22, TextNunitoRegular12 } from '../../../atoms';

export const Container = styled.div`
    ${tw`
        bg-[#698122]
        rounded-16
        p-4
        w-[320px]
        flex
        flex-col
        gap-2
    `}
`;

export const Title = styled(TextNunitoBold22)`
    ${tw`
        text-white
        text-center
        hidden
        md:block
    `}
`;

export const Description = styled(TextNunitoRegular12)`
    ${tw`
        text-white
        text-center
        h-[41px]
        mx-4
        hidden
        md:flex
        my-2
    `}
`;
export const TextMobile = styled.div`
    ${tw`
        flex
        text-16
        font-bold
        text-white
        md:hidden
        text-center
    `}
`;

export const Input = styled.input`
    ${tw`
        bg-[#F4F4F4]
        rounded-8
        p-4
        text-14
        text-black
        placeholder-[#BCBCBC]
        focus:border-0
        outline-none
        w-full
        mb-2
    `}
`;
