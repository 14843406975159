import dynamic from 'next/dynamic';
import { useProductUrlKey } from '../../../hooks/use-product-url-key';
import { ProductService, ReactGa4Service } from '../../../services';
import { IS_BROWSER } from '../../../services/query-client';
import CouponsBanner from '../../molecules/Coupons-banner';
import Layout from '../Layout';
import LayoutFitter from '../Layout-fitter';
import AddCartMobile from './components/AddCartMobile';
import Breadcrumb from './components/Breadcrumb';
import Hall from './components/Hall';
import Tabs from './components/Tabs';
import Title from './components/Title';
import ZipCode from './components/Zip-code';
import { QtyProductProvider } from './hooks/useQtyProduct';
import { useState } from 'react';
import Head from 'next/head';
import { NEXT_PUBLIC_TITLE, NEXT_PUBLIC_URL_API_NEXT, NEXT_PUBLIC_URL_IMAGES } from '../../../utils/envs';
import { DefaultSeo } from 'next-seo';
const Images = dynamic(() => import('./components/Images'), { ssr: false });
const Banner = dynamic(() => import('./components/Banner'), { ssr: false });

interface IProductDetailsTestProps {
    urlKey: string;
}

const ProductDetails = ({ urlKey }: IProductDetailsTestProps) => {
    const [hasFired, setHasFired] = useState<boolean>(false);
    const { data, isLoading } = useProductUrlKey(urlKey);
    if (IS_BROWSER) {
        ProductService.getByUrlKey(urlKey);
    }

    if (isLoading) return null;

    if (!data) return null;

    const valueDatalayer = data.price;
    const itemsDatalayer = {
        item_id: data.sku,
        item_name: data.name,
        price: data.price
    };

    if (!hasFired) {
        ReactGa4Service.onViewItem(itemsDatalayer, valueDatalayer);
        setHasFired(true);
    }

    return (
        <>
            <Head>
                <link rel="canonical" href={`${NEXT_PUBLIC_URL_API_NEXT}produto/${urlKey}`} />
            </Head>
            <DefaultSeo
                title={`${NEXT_PUBLIC_TITLE} | ${data.name}`}
                openGraph={{
                    images: [
                        {
                            url: `${NEXT_PUBLIC_URL_IMAGES}${data.ean}.jpg`,
                            width: 128,
                            height: 128,
                            alt: data.name
                        }
                    ]
                }}
            />
            <QtyProductProvider>
                <Layout>
                    <Breadcrumb data={data} />
                    <Banner data={data} />
                    <LayoutFitter withoutPaddingMobile>
                        <div className="flex flex-col gap-4 md:flex-row md:justify-between">
                            <Images data={data} />
                            <div className="flex flex-col flex-1 gap-4 px-4 md:px-0 md:max-w-lg">
                                <div className="flex flex-col gap-4 ">
                                    <Title data={data} />
                                    {/* <Sellers data={data} /> */}
                                </div>
                                <ZipCode showShippingMethod data={data} />
                            </div>
                        </div>
                    </LayoutFitter>
                    <LayoutFitter withoutPaddingMobile>
                        <Tabs data={data} />
                    </LayoutFitter>
                    <LayoutFitter withoutPaddingMobile>
                        <Hall data={data} />
                        <div className="pt-9 md:pt-12 md:pb-12">
                            <CouponsBanner />
                        </div>
                    </LayoutFitter>
                </Layout>
                {data.inStock && <AddCartMobile data={data} />}
            </QtyProductProvider>
        </>
    );
};
export default ProductDetails;
