import { FormikProvider, useFormik } from 'formik';
import { Button } from '../../../molecules';
import { Container, Description, Input, TextMobile, Title } from './styles';
import * as Yup from 'yup';
import { EMAIL, REQUIRED } from '../../../../utils/errors';
import { IButtonVariant } from '../../../molecules/Button/types';
import { HTMLAttributes } from 'react';
import { NewsletterService } from '../../../../services';
import { toast } from 'react-toastify';

// Validação do formulário
const validationForm = Yup.object().shape({
    email: Yup.string().email(EMAIL).required(REQUIRED)
});

interface IValuesForm {
    email: string;
}

function Newsletter(props: HTMLAttributes<HTMLDivElement>) {
    const formik = useFormik<IValuesForm>({
        initialValues: {
            email: ''
        },
        validateOnMount: false,
        validationSchema: validationForm,
        onSubmit: async (values) => {
            const response = await NewsletterService.addNewsletter(values.email);

            if(response.status === 200) {
                toast.success(
                    <div className="flex flex-row justify-between">
                        <span>E-mail cadastrado ao newsletter</span>
                    </div>,
                    {
                        position: 'bottom-center',
                        autoClose: 5000,
                        hideProgressBar: true,
                        className: 'text-sm'
                    }
                );
            } else {
                toast.error('Erro ao adicionar ao newsletter!');
            }
        
        }
    });

    return (
        <Container {...props}>
            <FormikProvider value={formik}>
                <form onSubmit={formik.handleSubmit}>
                    <TextMobile>Cadastre-se e fique por dentro das novidades e promoções</TextMobile>

                    <Title>Newsletter</Title>
                    <Description>Cadastre seu e-mail e fique por dentro das novidades e promoções</Description>

                    {/* Conecte o campo de email ao formik */}
                    <Input
                        type="email"
                        name="email"
                        placeholder="Seu e-mail"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                    />
                    
                    {/* Botão de submit */}
                    <Button
                        variant={IButtonVariant.BLACK_MEDIUM}
                        tw="text-22"
                        text="Cadastrar"
                        style={{width: '100%'}}
                        type="submit" // O botão agora é de submit
                    />
                </form>
            </FormikProvider>
        </Container>
    );
}

export default Newsletter;
