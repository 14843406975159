import tw, { styled } from 'twin.macro';
import { IconOrder } from '../../../atoms';

export const Icon = styled(IconOrder)`
    ${tw`
        text-[#9FBE43]
        w-6
        h-6
    `}
`;
