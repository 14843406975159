import tw, { styled } from 'twin.macro';
import { IButtonVariant, ICustomStyleProps } from './types';

export const Container = styled.button<ICustomStyleProps>`
    ${tw`
        flex
        flex-row
        gap-[10px]
        justify-center
        items-center
        p-4
        disabled:cursor-none
        disabled:pointer-events-none
        shrink-0
        font-nunito
        text-16
        font-bold
        
    `}
    ${({ variant }) => {
        switch (variant) {
            case IButtonVariant.PRIMARY_BIG:
                return tw`
                    min-h-[64px]
                    text-white
                    bg-primary
                    disabled:bg-gray
                    disabled:text-lightGray
                    rounded-28
                `;
            case IButtonVariant.PRIMARY_MEDIUM:
                return tw`
                    min-h-[56px]
                    text-white
                    bg-primary
                    disabled:bg-gray
                    disabled:text-lightGray
                    rounded-28
             
                `;
            case IButtonVariant.BLACK_MEDIUM:
                return tw`
                    min-h-[56px]
                    text-white
                    bg-black
                    disabled:bg-gray
                    disabled:text-lightGray
                    rounded-8
                `;
            case IButtonVariant.BLACK_MEDIUM_OUTLINE:
                return tw`
                    min-h-[56px]
                    text-black
                    border-black
                    border-[0.5px]
                    disabled:border-gray
                    disabled:text-lightGray
                    rounded-[32px]
                `;
            case IButtonVariant.BLACK_BIG_OUTLINE:
                return tw`
                    min-h-[64px]
                    text-black
                    border-black
                    border-[0.5px]
                    disabled:border-gray
                    disabled:text-lightGray
                    rounded-[32px]
                `;
            case IButtonVariant.OUTLINE:
                return tw`
                    border-[0.5px] 
                    min-h-[64px]
                    border-primary
                    text-primary
                    rounded-28
                `;
        }
    }}
`;
