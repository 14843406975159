import { useQuery } from '@tanstack/react-query';
import Image from 'next/image';
import { BannerService } from '../../../services';
import { NEXT_PUBLIC_HOME_BANNER_COUPON } from '../../../utils/envs';
import { BANNER } from '../../../utils/react-query-keys';
import { LayoutFitter } from '../../templates';

function CouponsBanner() {
    const { data: images, isLoading } = useQuery(
        [BANNER, NEXT_PUBLIC_HOME_BANNER_COUPON],
        () => BannerService.getBannerResponsiveById(NEXT_PUBLIC_HOME_BANNER_COUPON),
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false
        }
    );

    if (isLoading || !images?.length) {
        return null;
    }

    return (
        <LayoutFitter withoutPaddingMobile>
            <div className="hidden sm:flex">
                <Image
                    src={images?.[0].image as string}
                    className="object-contain object-left-top"
                    alt="Banner de cupons"
                    height={363}
                    width={1328}
                    sizes="1328px"
                />
            </div>
            <div className="flex justify-center sm:hidden ">
                <Image
                    src={images?.[0].imageMobile as string}
                    className="object-contain object-left-top"
                    alt="Banner de cupons"
                    width={411}
                    height={188}
                    sizes="411px"
                />
            </div>
        </LayoutFitter>
    );
}

export default CouponsBanner;
