import tw, { styled } from 'twin.macro';

export const Container = styled.div`
    ${tw`
        grid
        grid-rows-[auto]
        // grid-cols-[auto]

        lg:grid-cols-[2fr 5fr]      
        min-h-[80vh]  
        `}
`;

export const ProductsListContainer = styled.div`
    ${tw`
        col-start-2
        `}
`;
